.step #header {
  height: 58px;
  border-bottom: 2px solid #0075c2;
}
#headerin {
  width: 1000px;
  height: 100%;
  margin: 0 auto;
}
.titlebox {
  width: 100%;
  height: 50px;
  background-color: #eaeaea;
  margin-bottom: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 20px;
}


#header .titlebox {
  position: relative;
  float: left;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.step #header .titlebox {
  background-color: #fff;
  height: 54px;
  width: auto;
  margin-top: 18px;
}



div.head-mark {
  width: 112px !important;
  margin-left: 20px;
}


.step #header .titlebox{
  background-color: #fff;
  height:54px;
  width:auto;
}
.step9 #header .titlebox{
  height:60px;
  margin-top:12px;
}
  #header .titlebox #title{
    position:relative;
    float:left;
    height:60px;
  }
  .step #header .titlebox #title{
    margin-top:0;
    height:36px;
  }
  .step9 #header .titlebox #title{
    height:60px;
  }
  #header .titlebox div{
    position:relative;
    float:left;
    height:60px;
    margin-left:20px;
  }
  #header .titlebox #title img,
  #header .titlebox div img{
    height:100%;
  }
  #header #title span{
    margin-left:10px;
  }
  .step #header .titlebox #title {
    margin-top: 0;
    height: 36px;
}