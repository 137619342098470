@charset "utf-8";
#navi{
	float:right;
}
#page #navi .mypage,
#page #navi .login,
#page #navi .logout{
	float:left;
	margin:2px 20px 0 0;
	width:50px;
}
	#navi .mypage a,
	#navi .login a,
	#navi .logout a{
		width:100%;
	}
		#navi .mypage img,
		#navi .login img,
		#navi .logout img{
			width:100%;
		}
#page #navi .step{
	float:left;
	margin:0;
	width:140px;
	height:60px;
}
	#page #navi .step a{
		width:100%;
		height:100%;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: center;
		align-content: center;
		-webkit-justify-content: center;
		justify-content: center;
		background-image: url(../images/common/navi-step-back.png);
		background-position: center center;
		background-repeat: no-repeat;
		color:#fff;
		border-radius: 8px;
		position: relative;
	}
	#page #navi .step a::after{
		content: "";
	  display: inline-block;
	  background-image: url(../images/common/button-icon-step-wh.svg);
	  background-size: 30px 30px;
	  background-repeat: no-repeat;
	  background-position: center center;
		position: absolute;
		top:15px;
		right:10px;
		width:30px;
		height:30px;
	}
	#page #navi .step a span{
		padding-right:20px;
		text-align: center;
		line-height: 1.4;
	}

@media (max-width: 768px) {
	#page #navi{
		height:16vw;
	}
	#page #navi .mypage{
		display:none;
	}
	#page #navi .login,
	#page #navi .logout{
		float:left;
		margin:2.8vw 3vw 0 0;
		width:10vw;
	}
	#page #navi .step{
		margin:2.8vw 14vw 0 0;
		width:24vw;
		height:11.2vw;
	}
		#page #navi .step a{
			border-radius: 1.4vw;
		}
		#page #navi .step a::after{
		  background-size: 5vw 5vw;
		  background-repeat: no-repeat;
		  background-position: center center;
			position: absolute;
			top:2.6vw;
			right:1.5vw;
			width:5vw;
			height:5vw;
		}
		#page #navi .step a span{
			padding-right:4vw;
			text-align: center;
			line-height: 1.4;
			font-size:2.8vw;
		}
}

#step #naviin{
	position:relative;
	float:right;
}
#step.open #naviin{
	position:static;
}
#page #naviin{
	visibility: hidden;
	display: none;
}

/* ***********************************************************
* #global-nav
* *********************************************************** */
.step #global-nav {
	visibility: hidden;
	display: none;
}
.step.open #naviin{
	position:absolute;
	float:none;
	right:0;
	z-index: 20000;
}

.step.open  #global-nav {
	visibility: visible;
	position:absolute;
	display: table;
	top:0;
	right:0;
	width:460px;
	background-color: #0075c1;
	z-index: 30000;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	padding:70px 0 35px;
}
#nav-bg {
	content: "";
	top: 0;
	right: 0;
	width:0;
	height:0;
	display: block;
	position:absolute;
}
	.open #nav-bg {
		top:0;
		z-index: 20000;
	}
	.open #global-nav.menu .menuin{
		width:400px;
		margin:0 auto;
	}
	.open #global-nav.menu .menuin-mainlayer{
		margin-bottom:25px;
	}
	.open #global-nav.menu .menuin-mainlayer ul {
		margin:0 auto;
		display: table;
		width:100%;
	}
		.open #global-nav.menu .menuin-mainlayer li {
			width:190px;
		}
		.open #global-nav.menu .menuin-mainlayer li:nth-child(2n) {
			float:right;
			text-align:left;
		}
	.open #global-nav.menu .menuin-underlayer > ul {
		margin:0 auto;
		width:190px;
		float:left;
	}
	.open #global-nav.menu .menuin-underlayer > ul:nth-child(2n) {
		float:right;
		text-align:left;
	}
	.open #global-nav.menu .menuin-underlayer li.item{
		border-bottom:none;
	}
	.open #global-nav.menu .menuin-underlayer .item > ul{
		border-top:1px solid #fff;
	}
		.open #global-nav.menu li {
			float:left;
			display: table;
			width:100%;
			padding:0;
			margin:0;
			border-bottom:1px solid #fff;
		}
		.open #global-nav.menu li a{
			font-size:14px;
			line-height:1.4;
			height:auto;
			-webkit-display: flex;
			display: flex;
			-webkit-align-items: center;
			align-items: center;
			-webkit-flex-wrap: wrap;
			flex-wrap: wrap;
			-webkit-align-content: center;
			align-content: center;
			color:#fff;
			padding:10px 0 7px;
			margin:0;
			transition: 0 linear;
	    -webkit-transition: all 0 linear;
	    -moz-transition: all 0 linear;
	    -o-transition: all 0 linear;
	    -ms-transition: all 0 linear;
	    transition: all 0 linear;
		}
		.open #global-nav.menu li a:hover{
			opacity: 0.7;
		}
		.open #global-nav.menu .menuin-mainlayer li a{
			font-weight: bold;
		}
		.open #global-nav.menu .menuin-mainlayer li.my a{
			background-image: url(../images/common/navi-mypage.svg);
			background-position: left center;
			background-repeat: no-repeat;
			background-size: 26px auto;
			padding-left:38px;
		}
		.open #global-nav.menu .menuin-underlayer .item > ul li{
			padding-left:10px;
		}

@media (max-width: 768px) {
	#page #naviin{
		visibility: visible;
		display: block;
	}
	#page #global-nav {
		visibility: hidden;
		display: none;
	}
	#page.open #naviin{
		position:absolute;
		float:none;
		right:0;
		z-index: 20000;
	}

	#page.open #global-nav {
		visibility: visible;
		position:absolute;
		display: table;
		top:0;
		right:0;
		width:460px;
		background-color: #0075c1;
		z-index: 30000;
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
	}
}

/* ***********************************************************
* スマホ表示時
* *********************************************************** */
@media (max-width: 768px) {
	#page.open #global-nav.menu {
		width:100vw;
		top: 0;
		right: 0;
		visibility: visible;
		position:absolute;
		display: table;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		padding-top:15vw;
		padding-bottom:8vw;
	}
	#page.open #global-nav.menu ul{
		width:90vw;
		margin:0 auto;
	}
		.open #global-nav.menu li a{
			font-size:4vw;
			line-height:1.4;
			color:#fff;
			padding:3vw 0 2.8vw;
			margin:0;
			border-bottom: 0.2vw solid #fff;
		}
		#page.open #global-nav .menu li a:hover{
			border-bottom: 1vw solid #fff;
		}
	#page.open #nav-bg {
		width:100vw;
		height: 50000px;
		position: fixed;
		background:rgba(0,0,0,0.7);
	}

}



/* ***********************************************************
* #nav-toggle
* *********************************************************** */
	.step #nav-toggle {
		visibility:visible;
		position:absolute;
		right: 0;
		top: 0;
		width: 46px;
		height: 82px;
		cursor: pointer;
		padding: 0;
		z-index: 30001;
		display: flex;
  	-webkit-align-items: center;
  	align-items: center;
  	-webkit-flex-wrap: wrap;
  	flex-wrap: wrap;
  	-webkit-align-content: center;
  	align-content: center;
  	-webkit-justify-content: center;
  	justify-content: center;
	}
		.step #nav-toggle div {
	  	position: relative;
			z-index: 30002;
			width:46px;
			height:36px;
		}
		#nav-toggle span {
			display: block;
			position: absolute;
			height: 4px;
			width: 46px;
			background: #0075c1;
			right: 0;
			top:0;
			zoom: 1;
		}
		#nav-toggle span:nth-child(1) {top: 0;}
		#nav-toggle span:nth-child(2) {top: 16px;}
		#nav-toggle span:nth-child(3) {top: 32px;}
			.open #nav-toggle span:nth-child(1) {
				top: 14px;
				background:#fff;
				-webkit-transform: rotate(-135deg);
				transform: rotate(-135deg);
				zoom: 1;
			}
			.open #nav-toggle span:nth-child(2) {
				top: 14px;
				background:#fff;
				-webkit-transform: rotate(135deg);
				transform: rotate(135deg);
				zoom: 1;
			}
				.open #nav-toggle span:nth-child(3) {
					background: transparent;
				}
	#nav-toggle p {
  	position: relative;
		font-size:10px;
		color:#0075c1;
		letter-spacing: 2px;
		margin:10px auto 0;
	}
	#nav-toggle p.pclose {
  	display:none;
	}
	.open #nav-toggle p.pmenu {
  	display:none;
	}
	.open #nav-toggle p.pclose {
  	display:block;
		color:#fff;
	}

@media (max-width: 768px) {
	#page #nav-toggle {
		visibility:visible;
		position:absolute;
		right: 2vw;
		top: 5vw;
		width: 10vw;
		height: 10vw;
		cursor: pointer;
		padding: 0;
		z-index: 30001;
		-webkit-tap-highlight-color:rgba(0,0,0,0);
		display: flex;
  	-webkit-align-items: center;
  	align-items: center;
  	-webkit-flex-wrap: wrap;
  	flex-wrap: wrap;
  	-webkit-align-content: center;
  	align-content: center;
  	-webkit-justify-content: center;
  	justify-content: center;
	}
	#page #nav-toggle div {
		position: relative;
		z-index: 30002;
		width:10vw;
		height:5.4vw;
	}
	#page #nav-toggle span {
		height: 0.8vw;
		width: 8vw;
		right: 1vw;
	}
	#page #nav-toggle span:nth-child(1) {top: 0;}
	#page #nav-toggle span:nth-child(2) {top: 2.5vw;}
	#page #nav-toggle span:nth-child(3) {top: 5vw;}

	#page.open #nav-toggle span:nth-child(1) {
		top: 2.5vw;
	}
	#page.open #nav-toggle span:nth-child(2) {
		top: 2.5vw;
	}
	#page #nav-toggle .pmenu{
		font-size:2vw;
		width:8vw;
		text-align: center;
		margin:1.5vw auto 0;
	}
	#page.open #nav-toggle .pmenu{
		display:none;
	}
	#page #nav-toggle .pclose{
		font-size:2.8vw;
		width:10vw;
		text-align: center;
	}
}
	/* ---------- メニューここまで ---------- */


#header-navi{
	height:47px;
	background-color: #0075c2;
}
#header-navi nav{
	width:100%;
	height:100%;
}
	#header-navi ul{
		width:100%;
		height:100%;
		border-right:1px solid #71c5ea;
		display: flex;
	}
	#header-navi ul li{
		width: 100%;
		height:100%;
		border-left:1px solid #71c5ea;
	}
	#header-navi ul li a{
		width: 100%;
		height:100%;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: center;
		align-content: center;
		-webkit-justify-content: center;
		justify-content: center;
		color:#fff;
	}
	#header-navi ul li a:hover{
		background-color: #34a4e2;
		color:#fff;
	}
	#header-navi ul li a div{
		text-align: center;
		line-height: 1.2;
	}
	#header-navi ul li a span{
		font-size: 12px;
	}
@media (max-width: 768px) {
	#header-navi{
		visibility: hidden;
		display: none;
	}
}

@media (max-width: 768px) {
  #page #nav-toggle .pmenu {
      font-size: 2vw;
      width: 8vw;
      text-align: center;
      margin: 1.5vw auto 0;
  }
}
@media (max-width: 768px) {
  #page #nav-toggle .pclose {
      font-size: 2vw;
      width: 10vw;
      text-align: center;
  }
}

@media (max-width: 768px) {
  #page.open #nav-toggle .pmenu {
      display: none;
  }
}