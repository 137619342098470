
.ls10 {
  letter-spacing: 1em;
}
#headerin {
  width: 1000px;
  height: 100%;
  margin: 0 auto;
}

#page .contentsin {
  width: 1000px;
  min-width: 1000px;
  margin: 0 auto;
}

.page #contents {
  position: relative;
  padding: 60px 0 0;
}


#page #contents .topcomment p {
  margin: 0 auto;
  width: 100%;
  display: table;
}

.button {
  display: table;
  border-radius: 10px;
  margin: 0 auto;
  width: 210px;
  height: 50px;
  transition: 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

div, span {
  margin: 0;
  padding: 0;
  word-break: break-all;
  line-height: 1;
}


#page #contents .stitle-line {
  font-size: 42px;
  line-height: 1;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
  color: #2c90c8;
  width: 620px;
  height: 100px;
  background-image: url(../images/common/title-line.png);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: auto auto;
}

#page #contents .topcomment p {
  margin: 0 auto;
  width: 100%;
  display: table;
}


#page #contents .stitle-unerline {
  font-size: 28px;
  line-height: 1;
  font-weight: bold;
  text-align: left;
  margin: 0 auto;
  color: #0075c2;
  padding: 0 0 10px 20px;
  border-bottom: 1px solid #0075c2;
}


#page #headerin, .step9 #header #headerin {
  width: 1000px;
  height: 104px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}


.page #contents {
  position: relative;
  padding: 60px 0 0;
}
#page #contents .stitle{
  width:100%;
	height:70px;
	font-size:30px;
	font-weight:bold;
	background-image: url(../images/member/stitle-icon.png);
	background-position: 10px center;
	background-repeat: no-repeat;
	background-size: 57px auto;
	background-color: #2c90c8;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	line-height:1;
	color:#fff;
  padding-left:90px;
}
#page #contents .stitle2{
	height:50px;
	font-size:26px;
	font-weight:bold;
	display: -webkit-flex;
  display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: center;
	align-content: center;
	-webkit-justify-content: center;
	justify-content: center;
	line-height:0;
	color:#fff;
  background-color: #2c90c8;
}
#page #contents .stitle3 {
	font-size: 32px;
	font-weight: bold;
	text-align: center;
	margin: 0 auto;
	color: #2c90c8;
}
#page #contents .stitle4 {
	font-size: 42px;
	font-weight: bold;
	text-align: center;
	margin: 0 auto;
	color: #2c90c8;
}
#page #contents .stitle5 {
	width:100%;
	height:70px;
	font-size:28px;
	font-weight:bold;
	background-color: #0075c1;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	line-height:1;
	color:#fff;
  padding-left:20px;
}
#page #contents .stitle-line {
	font-size: 42px;
	line-height: 1;
	font-weight: bold;
	text-align: center;
	margin: 0 auto;
	color: #2c90c8;
	width:620px;
	height:100px;
	background-image: url(../images/common/title-line.png);
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: auto auto;
}
#page #contents .stitle-unerline {
	font-size: 28px;
	line-height: 1;
	font-weight: bold;
	text-align: left;
	margin: 0 auto;
	color: #0075c2;
	padding:0 0 10px 20px;
	border-bottom:1px solid #0075c2;
}

#contents .topcomment{
	display: table;
	margin:0 auto 30px;
}

#contents .topcomment p{
  margin:0 auto;
	width:100%;
	display:table;
}

#step .contentsin {
  width: 1000px;
  min-width: 1000px;
  margin: 0 auto;
}

#page #contents h1 {
  font-size: 42px;
  font-weight: bold;
  color: #0075c1;
  text-align: center;
  margin: 0 auto 30px;
}


@media (max-width: 768px) {
	#page #contents .stitle{
		height:11vw;
		font-size:5vw;
		padding-left:11vw;
	}
  #page #contents .stitle2{
  	font-size:4.6vw;
    height:auto;
    padding:2.5vw 0;
    line-height: 1;
  }
	#page #contents .stitle3 {
	  font-size: 5vw;
	}
	#page #contents .stitle4 {
	  font-size: 6vw;
	}
	#page #contents .stitle5 {
		padding:3vw 0 3vw 2.5vw;
		font-size:5vw;
		height:auto;
	}
	#page #contents .stitle-line {
		font-size: 6.4vw;
		line-height: 1.4;
		width:84vw;
		height:auto;
		padding-bottom:11vw;
	}

	.member #contents {
	   padding: 60px 0 0;
	}
	#contents .topcomment{
		margin:0 auto 5vw;
		width:90vw;
	}
	#contents .topcomment p{
	  margin:0 auto;
		width:auto;
		display:table;
	}
}



@media (max-width: 768px) {
	#page .contentsin, #page .s-contentsin, #page .s-contentsin2 {
			width: 90vw;
			min-width: 90vw;
	}
}


@media (max-width: 768px) {
  #footer .announcement-box .box:nth-child(1),
  #footer .announcement-box .box {
      width: 90vw;
  }
}



#page.flow #contents .step-box .box .comment-box .boxin li .button a:hover span {
	color:#fff;
}
@media (max-width: 768px) {
	#page body, #page dd, #page dt, #page h1, #page h2, #page h3, #page h4, #page h5, #page h6, #page li, #page p, #page a, #page td, #page th, #page address {
		font-size: 3.6vw;
		line-height: 1.8;
	}
	#footer {
		margin-top: 10vw;
	}
	#footer .announcement-box .box {
		width: 40vw;
		margin-bottom: 3vw;
	}
  #footer .link-box .box li a {
		font-size: 3vw;
		text-align: center;
		margin: 0;
		display: inline-block;
	}
	#footer .announcement-box .box:nth-child(1) {
		width: 46vw;
	}
  #footer .announcement-box .boxin {
    float: left;
    text-align: left;
    margin-top: 3vw;
    color: #7a7a7a !important;
  	width:100%;
  }
  #footer .announcement-box .boxin .link {
    text-align: left;
    margin: 1vw 0 3vw !important;
  }
  #footer .announcement-box .box:nth-child(3) .boxin .link {
    margin-top: 0;
  }
  #footer .announcement-box .boxin .link a{
    font-size: 3vw;
    text-align: center;
		margin: 0;
		display: inline-block;
  }
	#footer .link-box .box {
    width: 100%;
    float: left;
    margin: 4vw 0;
    border-right: none;
    padding: 0;
  }
	#page.payment #contents .box-contents .box-contentsin {
  	width: 100%;
	}
	#page.payment #contents .box-contents#postage .box-contentsin {
  	width: 100%;
	}
	#page #contents .stitle-unerline {
    font-size: 4.6vw;
		padding: 0 0 2vw 0;
	}
	#page.payment #contents .box-contents#pyment .box-contentsin dl dt {
    padding: 1.5vw 4vw;
    height: auto;
  }
	#page.payment #contents .box-contents#pyment .box-contentsin dl dt div {
    text-align: center;
    line-height: 1.4;
    font-weight: bold;
    font-size: 4vw;
    color: #fff;
	}
	#page.privacy #contents .box {
	  margin-bottom: 10vw;
	}
	#page.privacy #contents .box h2 {
	  font-size: 4vw;
	}
	#page.sitemap #contents .link-box {
		display: table;
	}
	#page.sitemap #contents .link-box .box {
	  width: 100%;
	}

	#contents .delivery-list .box .form-table caption {
		padding: 2vw 0 0;
	}
	.delete .boxin strong {
		font-size: 3.6vw;
	}
	.register #contents .address-table .id-tb td .button {
    margin: 3vw 0;
    width: 48vw;
	}
}


.text-align-left{
  text-align: left !important;
}
.mt-10{
  margin-top: 10px !important;
}
.mt-12{
  margin-top: 12px !important;
}
.mt-18{
  margin-top: 18px !important;
}
.mt-20{
  margin-top: 20px !important;
}

.max-width-800{
  max-width:800px !important;
}
.mb-40{
  margin-bottom: 40px !important;
}



#page.flow #contents .step-box .box .comment-box .img.card {
  margin-top: 25px;
  width: 470px;
  background: none;
  border: 0;
}


.member.login #contents .loginbox{
  padding: 20px 0 !important;
}
.member.login #contents .loginbox dl dt {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.member.login #contents .loginbox dl dd {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.member.login #contents input{margin: 0;}

.member.login #contents .loginbox .explanatio{
  padding-bottom:5px;
}

.member.login #contents .loginbox .explanation {
    margin: -5px 0 20px 201px!important;
}


@media screen and (max-width:768px){
  .member.login #contents .loginbox .explanation {
      margin: -5px 0 20px 3.5vw !important;}
  }


  @media screen and (min-width:769px){
    #contents .form-box .form-table.inq th {
        padding: 20px 5px 20px 10px;}
    }
