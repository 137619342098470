div.edit-box{
  position: relative;
}
div.svg-box{
  position: absolute;
  top:0;

}
div.svg-box.v{
  width:902px;
  height:100%;
  left:calc((100vw - 902px)/2);
  top:-83px
}
div.svg-box.z{
  width:1285px;
  height:100%;
  left:calc((100vw - 1285px)/2);
  top:-85px
}
div.svg-box img{
  width: 100%;
  height: auto;
}

#domContainer .selectable{
  z-index: 10;
  overflow: hidden;
}

.dummy_padding{
  padding:3px 0 0 2px;
}