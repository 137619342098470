@charset "utf-8";
.member #contents {
  position: relative;
  padding: 40px 0 0;
}

.password #contents {
  padding: 30px 0 0;
}
#page.member #contents h1{
	margin:0 auto 10px;
}
#page.member #contents h1 span{
	font-size:0.9em;
}
@media (max-width: 768px) {
  #page.member #contents h1{
  	margin:0 auto 2.5vw;
  }
}
/* ******************** ナビ ******************** */
#page #member-navi{
	height:46px;
	margin-bottom:50px;
	display:table;
}
	#member-navi ul{
		width:600px;
		height:100%;
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
    margin:0 auto;
	}
	#member-navi ul li{
		width:180px;
		height:100%;
	}
		#member-navi ul li a{
			width: 100%;
			height:100%;
			display: flex;
			-webkit-align-items: center;
			align-items: center;
			-webkit-flex-wrap: wrap;
			flex-wrap: wrap;
			-webkit-align-content: center;
			align-content: center;
			-webkit-justify-content: center;
			justify-content: center;
			color:#000000;
			background-color: #fff;
			border:2px solid #ccc;
			text-decoration: none;
			border-radius: 6px;
		}
		#member-navi ul li a:hover{
			border:2px solid #0075c1;
			background-color: #0075c1;
			color:#fff;
			text-decoration: none;
		}
		.mypage #member-navi ul li:nth-child(1) a,
		.order #member-navi ul li:nth-child(1) a,
		.delivery #member-navi ul li:nth-child(2) a,
		.register #member-navi ul li:nth-child(3) a,
		.withdrawal #member-navi ul li:nth-child(5) a{
			border:2px solid #0075c1;
			background-color: #0075c1;
			color:#fff;
			text-decoration: none;
		}
			#member-navi ul li a div{
				text-align:center;
				padding:4px 0 0;
			}
			#member-navi ul li a div span{
				font-size:10px;
				padding-top:4px;
				display:inline-block;
				line-height: 1;
			}

@media (max-width: 768px) {
	#page #member-navi{
		height:auto;
		margin-bottom:8vw;
		display:table;
	}
		#member-navi ul{
			width:84vw;
			height:auto;
			display: table;
			text-align:center;
		}
		#member-navi ul li{
			width:36vw;
			height:12vw;
			display:inline-table;
			margin-right:3vw;
      margin-left:3vw;
			margin-bottom:4vw;
			box-sizing: border-box;
      float:left;
		}
		#member-navi ul li:nth-child(2),
		#member-navi ul li:nth-child(4){
			margin-right:0;
		}
			#member-navi ul li a{
				width: 100%;
				height:100%;
				font-size:3.4vw;
				border-radius: 6px;
			}
			#member-navi ul li a div{
				text-align:center;
				padding:1vw 0 0;
			}
			#member-navi ul li a div span{
				font-size:2.6vw;
				padding-top:1.5vw;
			}
}


/* ******************** 共通 ******************** */

p a:hover{
	color:#004ba5;
}
#page #contents .question{
  display: inline-block;
  width:22px;
  margin:0 5px 0;
  line-height:1;
  vertical-align: middle;
}
  #page #contents .question img{
    width:100%;
    display: block;
    margin-bottom: 2px;
  }

#page #contents .stitle{
  width:100%;
	height:70px;
	font-size:30px;
	font-weight:bold;
	background-image: url(../images/member/stitle-icon.png);
	background-position: 10px center;
	background-repeat: no-repeat;
	background-size: 57px auto;
	background-color: #2c90c8;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	line-height:1;
	color:#fff;
  padding-left:90px;
}
#page #contents .stitle2{
	height:50px;
	font-size:26px;
	font-weight:bold;
	display: -webkit-flex;
  display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: center;
	align-content: center;
	-webkit-justify-content: center;
	justify-content: center;
	line-height:0;
	color:#fff;
  background-color: #2c90c8;
}
#page #contents .stitle3{
	font-size:32px;
	font-weight:bold;
	text-align: center;
	margin:0 auto;
	color:#2c90c8;
}
#page.register #contents .stitle3{
	margin:25px auto 0;
}
@media (max-width: 768px) {
	#page #contents .stitle{
		height:11vw;
		font-size:5vw;
		padding-left:11vw;
    background-size: 7.4vw auto;
    background-position: 1.3vw center;
	}
  #page #contents .stitle2{
  	font-size:4.6vw;
    height:auto;
    padding:2.5vw 0;
    line-height: 1;
  }
  #page #contents .stitle3{
  	font-size:5vw;
  }
}

.member #contents .topcomment{
	display: table;
	margin:0 auto 0;
}
.member.register #contents .topcomment p{
  	width:100%;
  	display:table;
    margin:20px auto 25px !important;
}

  

  .member #contents .topcomment li{
  	font-size:16px;
  	position:relative;
  	padding: 0 0 0 20px;
  	margin: 7px 0 7px 0;
  }
  .member #contents .topcomment li:before{
  		counter-increment: list;
  		content: "";
  		display: block;
  		position: absolute;
  		left: 0px;
  		height: 10px;
  		width: 10px;
  		border-radius: 50%;
  		background: #333;
  		top: 50%;
  		-moz-transform: translateY(-50%);
  		-webkit-transform: translateY(-50%);
  		-o-transform: translateY(-50%);
  		-ms-transform: translateY(-50%);
  		transform: translateY(-50%);
  	}

@media (max-width: 768px) {
  .member #contents .topcomment{
		margin:0 auto 2vw;
		width:90vw;
	}
	.member #contents .topcomment p{
	  margin:0 auto;
		width:auto;
		display:table;
    line-height:1.6;
	}
	.member #contents .topcomment li{
		font-size:3.6vw;
		position:relative;
		padding: 0 0 0 4vw;
		margin: 2vw 0 2vw;
	}
  .member #contents .topcomment li:before{
			height: 2vw;
			width: 2vw;
			top: 3vw;
		}
}



/* ******************** ボタン ******************** */


div.member div.button {
	display: inline-block;
} 
.member .button.new {
	width:300px;
}
.member.button a{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		padding: 0;
		line-height:1;
		font-size:18px;
		border-radius: 6px;
		font-weight: normal;
	}
  .member .button.or a{
    background-color: #ed772c;
    color: #fff;
  }

  .member .button.or a:hover {
		background-color: #d34b00;
		color: #fff;
	}
  .member .button.gr a{
    background-color: #cccccc;
    color: #333;
  }
	.member .button.gr a:hover {
		background-color: #d34b00;
		color: #fff;
	}
  .member .button.rd a{
		background-color:#c82600;
		color:#fff;
	}
  .member .button.rd a:hover{
    background-color:#8e1700;
    color:#fff;
  }
@media (max-width: 768px) {
  .buttonbox.pt1 .button:nth-child(1){
    margin-right:4vw;
  }
	.button  {
		font-size:4vw;
		border-radius: 1.4vw;
	}

}

#contents .input-button {
	-webkit-display: flex;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}
#contents .input-button input {
  display: none;
}
	#contents .input-button input + label {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		padding: 0;
		background-color: #ed772c;
		color: #fff;
		line-height:1;
		font-size:16px;
		border-radius: 6px;
	}
	#contents .input-button input + label:hover {
		background-color: #0075c1;
		color: #fff;
	}
@media (max-width: 768px) {
	#contents .input-button input + label {
		font-size:4vw;
		border-radius: 1.4vw;
	}
	#contents .input-button input + label:hover {
		color: #fff;
	}
}

/* ******************** エラー ******************** */
.member #contents .errorbox{
	background-color: #ffe6e6;
	padding:10px 30px;
	margin:0 auto 30px;
	width:600px;
}
.member #contents .errorbox li{
		font-size:16px;
		font-weight:bold;
		color:#c13014;
		position:relative;
	  padding: 0 0 0 20px;
	  margin: 7px 0 7px;
	}
	.member #contents .errorbox li:before{
	  counter-increment: list;
	  content: "";
	  display: block;
	  position: absolute;
	  left: 0px;
	  height: 10px;
	  width: 10px;
	  border-radius: 50%;
	  background: #c13014;
	  top: 50%;
	  -moz-transform: translateY(-50%);
	  -webkit-transform: translateY(-50%);
	  -o-transform: translateY(-50%);
	  -ms-transform: translateY(-50%);
	  transform: translateY(-50%);
	}

  .member #contents .error-box{
  	background-color: #ffe6e6;
  	padding:10px 20px;
  	margin:0 auto 0;
  	width:auto;
  	display:table;
    font-size:14px;
  	font-weight:bold;
  	color:#c13014;
  }
  .register #contents .error-box,
  .delivery #contents .error-box{
  	margin:0 0;
  }
  .member #contents .error-box li{
    	font-size:14px;
    	font-weight:bold;
    	color:#c13014;
    	position:relative;
      padding: 0 15px 0 25px;
      margin: 7px 0 7px 0px;
    }
    .member #contents .error-box li:before{
      counter-increment:inherit;
      content: "";
      display: block;
      position: absolute;
      left: 9px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: #c13014;
      top: 45%;
      -moz-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }

@media (max-width: 768px) {
	#contents .errorbox{
		padding:3vw 4vw;
		margin:0 auto 6vw;
		width:100%;
	}
		#contents .errorbox li{
			font-size:3.6vw;
		  padding: 0 0 0 3vw;
		  margin: 2vw 0;
		}
		#contents .errorbox li:before{
			height: 2vw;
			width: 2vw;
			top: 3vw;
		}
  #contents .error-box{
		padding:3vw 4vw;
		margin:0 auto 0;
		width:100%;
	}
		#contents .error-box li{
			font-size:3.6vw;
		  padding: 0 0 0 4vw;
		  margin: 2vw 0;
		}
		#contents .error-box li:before{
			height: 2vw;
			width: 2vw;
			top: 3vw;
		}
}

/* ******************** ログイン ******************** */



.member.login #contents .loginbox{
	border:1px solid #ccc;
	display:table;
	padding:40px 0;
	border-radius: 6px;
}
	.member.login #contents .loginbox .loginboxin{
		display:table;
		width:900px;
		margin:0 auto;
	}
		.member.login #contents .loginbox .l{
			width:500px;
		}
		.member.login #contents .loginbox .r{
			width:340px;
		}
			.member.login #contents .loginbox h2{
				font-size:18px;
			}
			.member.login #contents .loginbox dl{
				display:table;
				width:100%;
			}
				.member.login #contents .loginbox dl dt{
					display:table-cell;
					padding:6px 10px;
					width:190px;
				}
				.member.login #contents .loginbox dl dd{
					display:table-cell;
					padding:6px 10px;
				}
    .member.login #contents .loginbox .explanation{
			margin-left:194px;
		}
      .member.login #contents .loginbox .explanation a{
  			font-size:13px;
  		}

		.member.login #contents .input-button.login,
		.member.login #contents .input-button.register {
		  height: 40px;
		  width: 160px;
		  margin: 10px auto 15px;
		}

		.member.login #contents .input-button.register input + label {
			background-color: #00c14e;
		}
			.member.login #contents .input-button.login input + label:hover {
				background-color: #d34b00;
			}
			.member.login #contents .input-button.register input + label:hover {
				background-color: #009600;
			}
		.member.login #contents .loginbox .r p:nth-child(1){
			text-align:center;
			margin:30px auto 20px;
		}
@media (max-width: 768px) {
	.member.login #contents {
	    padding: 6vw 0 0;
	}
	.member.login #contents .loginbox{
		padding:5vw 0;
		border-radius: 2vw;
	}
		.member.login #contents .loginbox .loginboxin{
			width:100%;
		}
			.member.login #contents .loginbox .l{
				width:80vw;
				float:none;
				margin:0 auto;
			}
			.member.login #contents .loginbox .r{
				width:80vw;
				float:none;
				margin:5vw auto 0;
				padding-top:5vw;
				border-top: 1px solid #ccc;
			}
				.member.login #contents .loginbox h2{
					font-size:4vw;
				}

					.member.login #contents .loginbox dl dt{
						display:table;
						padding:1vw 3vw 0.2vw;
						width:100%;
					}
					.member.login #contents .loginbox dl dd{
						display:table;
						padding:0 3vw 1vw;
						width:100%;
					}
    .member.login #contents .loginbox .explanation{
			margin-left:3.5vw;
		}
      .member.login #contents .loginbox .explanation a{
  			font-size:3vw;
  		}

			.member.login #contents .input-button.login,
			.member.login #contents .input-button.register {
			  height: 10vw;
			  width: 36vw;
			  margin: 2vw auto 4vw;
			}
			.member.login #contents .input-button.register input + label {
				background-color: #00c14e;
			}
				.member.login #contents .input-button.register input + label:hover {
					background-color: #009600;
				}
			.member.login #contents .loginbox .r p:nth-child(1){
				text-align:center;
				margin:30px auto 20px;
			}
}

/* ******************** 入稿データ一覧・再入校 ******************** */
.mypage #contents{
	padding: 60px 0 0;
}
.mypage #contents .button{
	margin:0 auto;
  display: block;
}

.draft-data-list .comment{
	margin:25px 0 30px;
}
  .draft-data-list .comment p{
    margin:0;
    padding:0;
  }
  .draft-data-list .comment div{
    margin:10px 0 0;
    padding:0;
    font-size: 16px;
    line-height: 1.6;
  }
  .draft-data-list .comment dl{
  	display: table;
    margin:5px 0 0;
  }
    .draft-data-list .comment dl dt{
    	display: table-cell;
      width:140px;
    }
    .draft-data-list .comment dl dt div{
    	display: table;
      width:100%;
      text-align: center;
      font-size:16px;
      color:#fff;
      background-color: #52b3ea;
      padding:6px 0;
    }


    .draft-data-list .comment dl dd{
    	display: table-cell;
      padding-left:15px;
    }


.draft-data-list .box{
	width:100%;
	border:1px solid #999;
	border-left:5px solid #2c90c8;
	margin:0 0 50px;
	padding:20px 0;
	text-align:center;
}
.draft-data-list .box .boxin{
	width:730px;
	text-align:left;
	margin:0 auto;
}

.status{
	background-color: #f2f2f2;
}
	.table dt{
		display: table-cell;
		vertical-align: top;
		width:180px;
		padding:7px 10px;
		font-weight:bold;
	}
		.table dd{
			display: table-cell;
			vertical-align: top;
			width:546px;
			padding:7px 10px 7px 0;
		}
		.content dl{
			float:left;
			width:267px;
		}
		.content dl dt{
			width:6em;
			padding:0 0 5px;
		}
			.content dl dd{
				width:calc(267px - 6em);
				display: table-cell;
				vertical-align: top;
				padding:0 0 5px;
			}
			.content dl dd::before {
				content: ": ";
			}
	.draft-data-list p.min{
		padding:5px 10px 15px;
		font-size:12px;
	}
	.nontable dt{
		width:100%;
		padding:7px 10px;
		font-weight:bold;
	}
		.nontable dd{
			width:100%;
			padding:0 10px 7px 10px;
		}


.draft-data-list .box .error-box{
  padding:10px;
  margin:0 auto;
  text-align: center;
  display:table;
}


.draft-data-list .list-navi{
	height:46px;
	display:table;
	width:100%;
	margin-top:15px;
}
	.draft-data-list .list-navi ul{
		width:100%;
		height:100%;
		display: table;
	}
	.draft-data-list .list-navi ul li{
		width:170px;
		height:50px;
    float:left;
    margin-right:15px;
	}
  .draft-data-list .list-navi ul li:nth-child(4n){
    margin-right:0;
	}
  .draft-data-list .list-navi ul li.sample{
		width:250px;
		height:50px;
    float:left;
    margin-top:15px;
	}
		.draft-data-list .list-navi ul li a,
    .draft-data-list .list-navi ul li .disabled{
			width: 100%;
			height:100%;
			display: flex;
			-webkit-align-items: center;
			align-items: center;
			-webkit-flex-wrap: wrap;
			flex-wrap: wrap;
			-webkit-align-content: center;
			align-content: center;
			-webkit-justify-content: center;
			justify-content: center;
			color:#000000;
			background-color: #fff;
			border:2px solid #ccc;
			font-size:16px;
			text-decoration: none;
			border-radius: 6px;
		}
    .draft-data-list .list-navi ul li .disabled{
      color: #000 !important;
      background-color: #ccc !important;
      pointer-events: none;
		}
		.draft-data-list .list-navi ul li a:hover{
			border:2px solid #ed772c;
			background-color: #ed772c;
			color:#fff;
			text-decoration: none;
		}
			.draft-data-list .list-navi ul li a div{
				text-align:center;
				padding:4px 0 0;
			}
			.draft-data-list .list-navi ul li a div span{
				font-size:10px;
				padding-top:4px;
				display:inline-block;
				line-height: 1;
			}

@media (max-width: 768px) {
  .draft-data-list .box{
  	margin:0 0 10vw;
  	padding:4vw 0;
  }
  .draft-data-list .box .boxin {
      width: 80vw;
  }
  .table {
    width:100%;
  }
    .table dt {
      width: 24vw;
      padding: 1vw 4vw 1vw 2vw;
    }
    .table dd {
      vertical-align: middle;
      width: calc(100% - 24vw);
      padding: 1vw 2vw 1vw 0;
    }
    .table.content > dt{
      display: block;
    }
    .table.content > dd{
      display: block;
      width:100%;
    }
    .table.content dl {
      width:100%;
      float:none;
    }
    .table.content dd dt {
      width: 24vw;
      padding: 1vw 4vw 1vw 2vw;
    }
    .table.content dd dd {
      width: calc(100% - 24vw);
      padding: 1vw 2vw 1vw 0;
    }


	.draft-data-list .list-navi{
		height:auto;
		margin-bottom:0;
		display:table;
    width:80vw;
	}
		.draft-data-list .list-navi ul{
			width:100%;
			height:auto;
			display: table;
			text-align:center;
		}
		.draft-data-list .list-navi ul li{
			width:36vw;
			height:12vw;
			display:inline-table;
			margin-right:3vw;
			margin-bottom:3vw;
			box-sizing: border-box;
		}
		.draft-data-list .list-navi ul li:nth-child(3),
		.draft-data-list .list-navi ul li:nth-child(5){
			margin-right:0;
		}
			.draft-data-list .list-navi ul li a{
				width: 100%;
				height:100%;
				font-size:3.4vw;
				border-radius: 6px;
			}
			.draft-data-list .list-navi ul li a div{
				text-align:center;
				padding:1vw 0 0;
			}
			.draft-data-list .list-navi ul li a div span{
				font-size:2.6vw;
				padding-top:1.5vw;
			}
}

.delete .boxin{
	text-align:center;
}
.delete .boxin p{
	text-align:center;
}
.delete .boxin strong{
	font-size: 18px;
	font-weight: bold;
}


.button.new {
	width:300px;
}

@media (max-width: 768px) {
	.button.new  {
		font-size:4vw;
		border-radius: 1.4vw;
	}
	.button.new :hover {
		color: #fff;
	}
}


/* ******************** 追加配送先 ******************** */
#contents .delivery-list .box{
	width:100%;
	margin:0 0 50px;
	padding:20px 0 0;
	text-align:center;
}
  #contents .delivery-list .box .boxin{
  	/** width:760px; **/
  	text-align:left;
  	margin:0 auto;
  }
  #contents .delivery-list .box .form-table caption{
  	font-size: 28px;
  	font-weight: bold;
  	color:#0075c1;
  	padding:10px 0;
  }

  #contents .delivery-list .form-table th{
  	position: relative;
  	width:270px;
  	min-height:75px;
  	display: -webkit-flex;
  	display: flex;
  	-webkit-align-items: center;
  	align-items: center;
  	-webkit-justify-content: space-between;
  	justify-content: space-between;
  	padding-left:10px;
  }
  #contents .delivery-list .form-table th .thname{
  	float:left;
  	position: relative;
  	font-size: 18px;
  	text-align: left;
    line-height:1.4;
  }
  #contents .delivery-list .form-table th .required{
  	float:right;
    margin-left: 20px;
    width: 42px;
    height: 16px;
    font-size: 11px !important;
    line-height: 1 !important;
    font-weight: normal;
    color: #fff;
    background-color: #da4b28;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-justify-content: center;
    justify-content: center;
    letter-spacing: 3px;
    padding-left: 3px;
  }
  #contents .delivery-list .form-table td {
  	padding: 7px 15px 7px;
  	width:530px;
    text-align: left;
    vertical-align: middle;
  }

  #contents .delivery-list .form-table input{
  	padding: 4px;
  	border: none;
  	border-radius: 4px;
  	text-align:left;
  	margin:5px 0;
  	line-height: 1.8;
  	font-size: 1em;
  	border:1px solid #999999;
  	box-sizing: border-box;
  }
  #contents .delivery-list .form-table input.inputbox02{
  	width:100%;
  }
  #contents .delivery-list .form-table input.inputbox03{
  	width:4em;
  }
  #contents .delivery-list .form-table input.inputbox04{
  	width:5em;
  }
  #contents .delivery-list .form-table select{
  	-webkit-appearance: none;
  	-moz-appearance: none;
  	appearance: none;
  	vertical-align:middle;
  	font-size:14px;
  }
  #contents .delivery-list .form-table select::-ms-expand {
  	display: none;
  }
  #contents .delivery-list .form-table select {
  	width: 220px;
  	height: 40px;
  	position: relative;
  	padding: 0 10px;
  	-webkit-appearance: none;
  	-moz-appearance: none;
  	appearance: none;
  	border: 1px solid #999;
  	background: #eee;
  	/** background: url(arrow01.png) right 50% no-repeat;
  	background: url(../images/common/arrow-select-bt.svg) right 50% no-repeat; **/
  	background-size: 20px, 100%;
  }


  .delivery-list .err{background-color:#ffe6e6;}
  .delivery-list .err input,
  .delivery-list .err textarea,
  .delivery-list .err select{
    background-color:#fcc !important;
  }
  .delivery-list .errbox{
    font-weight:bold;
    color:#c30;
  }

  .delivery-list .buttonbox{
    margin:20px auto;
    text-align: center;
  }

#list.delivery-list h2{
  margin:0 auto 40px;
}
#contents #list.delivery-list .box {
    padding: 0;
}
#list.delivery-list .buttonbox{
  margin:20px auto 60px;
  display:table;
}
#list.delivery-list .buttonbox .comp{
  width:100%;
  display:table;
  text-align: center;
  padding:5px 0 25px;
  font-size:16px;
  font-weight:bold;
}
#list.delivery-list .buttonbox.pt1 .button{
  margin:0 15px;
}

.buttonbox.pt1 .button:nth-child(1) {
  margin-right: 40px;
}


/* アコーディオン */
#list.delivery-list .accordion-box{
  position: relative;
}
#list.delivery-list .accordion-box .check{
  display: none;
}
/* 通常時表示 */
#list.delivery-list .accordion-box .check-label{
  background: #f0f0f0;
  display: block;
  align-items: center;
  padding: 11px;
  width: 798px;
  height:40px;
  border-top:1px solid #999;
  margin-top:-1px;
  box-sizing: border-box;
}
#list.delivery-list .accordion-box .check-label::before{
  content: "▼";
  position: absolute;
  display: block;
  font-size:20px;
  left:50%;
  margin-left:-10px;
  width:20px;
}
#list.delivery-list .accordion-box .bt-table{
  overflow: hidden;
  height: 0;
  transition: 0.5s;
}
#list.delivery-list .accordion-box .check:checked + .check-label{
  position: absolute;
  bottom:0;
  left:0;
  right:0;
}
#list.delivery-list .accordion-box .check-label.open::before{
  content: "▲";
}

#list.delivery-list .accordion-box .check:checked + .check-label + .bt-table{
  height: auto;
}



#list.delivery-list .buttonbox.pt1 .button{
  display: inline-block !important;
}

@media (max-width: 768px) {
  #contents .delivery-list .box{
  	margin:0 0 10vw;
  	padding:6vw 0 0;
  }
    #contents .delivery-list .box .boxin{
    	width:84vw;
    }
    #contents .delivery-list .box .form-table{
    	width:100%;
    }
    #contents .delivery-list .box .form-table caption{
    	font-size: 4.8vw;
    	padding:0 0 2vw 0;
    }
    #contents .delivery-list .form-table table{
    	width:100%;
    }
    #contents .delivery-list .form-table th{
    	width:100%;
    	min-height:auto;
    	display: block;
    	padding:4vw 0 4vw 3vw;
    }
    #contents .delivery-list .form-table th br{
    	display: none;
    }
    #contents .delivery-list .form-table th .thname{
    	font-size: 3.8vw;
    }
    #contents .delivery-list .form-table th .required{
      margin-right: 3vw;
      width: 10vw;
      height: 4vw;
      line-height: 4vw;
      font-size: 2.6vw !important;
    }
    #contents .delivery-list .form-table td {
    	padding: 2vw 3vw 4vw;
    	width:100%;
      display: block;
    }

    #contents .delivery-list .form-table input{
    	padding: 1vw 2vw;
    	border: none;
    	border-radius: 1vw;
    	text-align:left;
    	margin:0 0;
    	font-size: 3.4vw;
    	border:1px solid #999999;
    }
    #contents .delivery-list .form-table input.inputbox03{
    	width:12vw;
    }
    #contents .delivery-list .form-table input.inputbox04{
    	width:18vw;
    }
    #contents .delivery-list .form-table select{
    	font-size:3.4vw;
    }
    #contents .delivery-list .form-table select {
    	width: 60vw;
    	height: 12vw;
    	position: relative;
    	padding: 0 3vw;
    	background-size: 6vw, 100%;
    }

  .delivery-list .buttonbox{
    margin:4vw auto;
  }
  #list.delivery-list .buttonbox{
    margin:4vw auto 15vw;
  }

  #list .delivery-list .buttonbox .button:nth-child(1){
    margin-right:4vw;
  }

  #list.delivery-list .buttonbox .comp{
    padding:2vw 0 4vw;
    font-size:4vw;
  }
  #list.delivery-list .buttonbox.pt1 .button{
    margin:0 1.5vw;
  }


  #list.delivery-list .accordion-box .check-label{
    padding: 2vw;
    width: calc(90vw - 2px);
    height:9vw;
  }
  #list.delivery-list .accordion-box .check-label::before{
    font-size:5vw;
    margin-left:-2.5vw;
    width:5vw;
  }
}

.delivery-list p.min.atten {
  font-size: 14px;
  text-align: left;
  padding-left: 1em;
  text-indent: -1em;
  margin: 0 0 0 285px;
}

/* ******************** 登録内容のご確認 ******************** */
.register #contents .topcomment p {
    border: none;
    padding: 0;
}
.register #contents .delivery-list .box {
    border: none;
    padding: 0;
}
.register #contents .delivery-list .box .atten{
    font-size:14px;
    text-align: left;
    padding-left:1em;
  	text-indent: -1em;
    margin:20px 0 0 10px;
}
.register #contents .delivery-list .box .atten:before{
    content:"※";
}
.register .delivery-list .buttonbox{
  margin:60px auto 0;
}
.register #contents .link{
  display:table;
  width:100%;
}
  .register #contents .link a{
    float:right;
    display:block;
  }
  .register #contents .link a span{
    display: inline-block;
    padding-left:5px;
  }
  .register #contents .link a:before{
    content:"▶";
    display: inline-block;
  }

@media (max-width: 768px) {
  .register #contents .delivery-list .box .atten{
      font-size:3vw;
      text-align: left;
      padding-left:1em;
    	text-indent: -1em;
      margin:2vw 0 0 3vw;
  }
  .register .delivery-list .buttonbox{
    margin:9vw auto 0;
  }
  .register #contents .link a{
    float:left;
  }
}


/* ******************** 登録完了 ******************** */
.register #contents p {
  border: none;
  width:100%;
  margin: 20px auto 25px;
  display: table;
}
.register.completion #contents .buttonbox{
  margin:50px auto 0;
  text-align: center;
}

.temporary-registration #contents .topcomment p {
  border: none;
  padding: 0;
  width: auto !important;
  display: table;
}


@media (max-width: 768px) {
  .register.completion #contents .topcomment p {
      padding: 4vw 0 0;
      width:100%;
  }
  .register .delivery-list .buttonbox{
    margin:9vw auto 0;
  }
}


.temporary-registration .button a,
.temporary-registration .button div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0;
  line-height: 1;
  font-size: 18px;
  border-radius: 6px;
  font-weight: normal;
}


/* ******************** 会員退会 ******************** */
#page.withdrawal #contents .stitle3 {
  margin: 30px auto 0;
}
#page.withdrawal #contents .topcomment p {
  border: none;
  padding: 30px 0 0;
  width:auto;
}
#page.withdrawal #contents .topcomment p {
  width:auto;
  margin:0 auto;
}
#page.withdrawal #contents .delivery-list{
  width: 600px;
  min-width: 600px;
  margin:40px auto 0;
}
#page.withdrawal #contents .delivery-list .form-table{
    width: 100%;
  }
  #page.withdrawal #contents .delivery-list .form-table tr {
    border-bottom: none;
  }
  #page.withdrawal #contents .delivery-list .form-table th {
    width: 160px;
  }
  #page.withdrawal #contents .delivery-list .form-table td > div{
      width: 100%;
    }
    #page.withdrawal #contents .delivery-list .form-table td .error-box{
      width: auto;
      margin:5px 0 0;
    }
#page.withdrawal #contents .delivery-list .box {
  border: none;
  padding: 0;
}
#page.withdrawal #contents .buttonbox{
  margin:30px auto 0;
  text-align: center;
}

#page.withdrawal #contents .non-withdrawal{
  text-align:center;
}
#page.withdrawal #contents .non-withdrawal strong{
  font-weight:bold;
  font-size:1.4em;
  color:#cc3300;
}

@media (max-width: 768px) {
  .withdrawal #contents .topcomment p,
  .withdrawal #contents .topcomment p {
      padding: 4vw 0 0;
      width:100%;
  }
  .withdrawal #contents .delivery-list{
    width: 80vw;
    min-width: 80vw;
    margin:5vw auto 0;
  }
    .withdrawal #contents .delivery-list .form-table th {
      width: 100%;
      display:block;
      float:none;
      padding: 2vw 0 4vw;
    }
    .withdrawal #contents .delivery-list .form-table td {
      width: 100%;
      display:block;
      float:none;
      padding: 4vw 0 0;
    }
    .withdrawal #contents .delivery-list .form-table td .error-box{
      margin:2vw 0 0;
    }
  .withdrawal #contents .buttonbox{
    margin:5vw auto 0;
  }
}



/* ******************** 仮会員登録 ******************** */
.temporary-registration #contents .topcomment p {
    border: none;
    padding: 0;
    width:auto;
    display: table;
}
.temporary-registration #contents .delivery-list .box {
    border: none;
    padding: 0;
}
.temporary-registration #contents .delivery-list .form-table tr {
    border-bottom: none;
}
.temporary-registration  #contents .delivery-list .box .atten{
    font-size:14px;
    text-align: left;
    padding-left:1em;
  	text-indent: -1em;
    margin:0 0 0 285px;
}
.temporary-registration #contents .delivery-list .box .atten:before{
    content:"※";
}
.temporary-registration #contents .buttonbox{
  margin:60px auto 0;
  text-align: center;
}
@media (max-width: 768px) {
  .temporary-registration #contents .topcomment p{
      padding: 4vw 0 0;
      width:100%;
  }
  .temporary-registration #contents .delivery-list .box .atten{
      font-size:3vw;
      text-align: left;
      padding-left:1em;
    	text-indent: -1em;
      margin:2vw 0 0 3vw;
  }
  .temporary-registration #contents .buttonbox{
    margin:9vw auto 0;
  }
}


/* ******************** 登録内容確認 ******************** */
.temporary-registration.check #contents .topcomment p {
    width:600px !important;
}
.temporary-registration.check #contents .delivery-list .form-table tr {
    border-bottom: 1px solid #999;
}
@media (max-width: 768px) {
  #page .temporary-registration.check #contents .topcomment p{
      padding: 4vw 0 0;
      width:100%;
  }
}



/* ******************** パスワード再設定
 ******************** */
 
 #page.password #contents .step-box{
   width:550px;
   margin:0 auto 50px;
 }
 #page.password #contents .step-box img{
     width:100%;
   }

 #page.password #contents .topcomment p {
   width:auto;
   display:table;
   margin: 0 auto 25px;
   padding: 30px 0 0;
 }
 #page.password.completion #contents .topcomment p {
   display:table;
   margin: 0 auto 25px;
 }
 #page.password #contents .delivery-list .box {
   border: none;
   padding: 0;
 }
 #page.password #contents .delivery-list .form-table tr {
    border-bottom: none;
 }
 #page.password #contents .delivery-list .button {
    width:280px;
 }

 @media (max-width: 768px) {
   .password #contents .step-box{
     width:100%;
     margin:0 auto 5vw;
   }
   .password #contents .topcomment p{
       padding: 4vw 0 0;
       width:100%;
   }
   .password #contents .delivery-list .button {
       width:58vw;
   }
 }


 .register #contents .address-table .id-tb td .button{
   margin:15px 0;
   width: 280px;
 }



#page.email-reset #contents .topcomment p,
#page.email-success #contents .topcomment p{
 width:auto;
 display:table;
 margin:30px auto 25px;
}


#contents .delivery-list .form-table tr {
  border-bottom: 1px solid #999;
}

#contents .delivery-list .box {
  width: 100%;
  border: 1px solid #999;
  margin: 0 0 50px;
  padding: 20px 0 0;
  text-align: center;
}
#contents .delivery-list .box.payment{
  border: none;
}
#contents .delivery-list .box.payment {
  padding: 0;
}
#list.delivery-list .buttonbox {
  margin: 20px auto 60px;
  display: table;
}



