
:root {
  --a3d-width: 360px;
}
.img.l{
  float: left;
  width: 360px;
  overflow: hidden;
}
.img.r {
  width: 360px;
  overflow: hidden;
}
.a3dview-box{
  position: relative;
  display: flex;
  width: var(--a3d-width);
  flex-direction: row;
  height: 300px;
  width: 400px;
}
.a3dview-box div{

  overflow: hidden;
  display: flex;
}

div.imagebox div.image{
  width: 294px;
  /** height: 168px; **/
  overflow: hidden;
}
div.imagebox.v div.image{
  width: 480px;
  height: 300px;
  overflow: hidden;
}



.a3dview-box div.left{
  position: relative;
  overflow: hidden;

}

.a3dview-box div.right{
  position: relative;
  overflow: hidden;
  box-sizing: border-box;

}

div.a3dview-box.type-z div.left{


  position: relative;
  justify-content: center;
  transform: perspective(200px) rotateY(-10deg);
  transform-origin: center right;
  overflow: hidden;
  margin-left: -25px;

  
 
}

.a3dview-box.type-z div.center{
  
  transform: perspective(200px) rotateY(15deg);
  transform-origin: center left; 
  overflow: hidden;
  scale: .94;
  margin-left: -60px;
}


.a3dview-box.type-z div.right{

  position: relative;
  justify-content: center;
  transform: perspective(200px) rotateY(-10deg);
  transform-origin: center right;
  overflow: hidden;
  scale: .96;
  margin-left: -146px;
 
}

.a3dview-box.type-z div.in{
  position: relative;
  overflow: hidden;
  width: 200px;
}


div.a3dview-box.type-z div.in img{
  width: 400px;

  position:absolute;

}
div.a3dview-box.type-z div.center img{
  margin-left: -141px;
}
div.a3dview-box.type-z div.left img{

}
div.a3dview-box.type-z div.right img{
  margin-left: -258px;
}



div.a3dview-box div.cover{
  display: block; 
 
  position: absolute;
  top:0;
  left:0;
  z-index: 20;
  opacity: 1;
}

div.a3dview-box.type-z div.cover{
  width: 379px;

  top: -10px;
  left: -12px;
}



div.a3dview-box div.cover img{
  width: 100%;
  height: 100%;
}

div.a3dview-box.type-v div.cover{
  width: 398px;

  top: 0px;
  left: -18px;
}


div.a3dview-box.type-v div.left{
  transform: perspective(200px) rotateY(-10deg);
  transform-origin: center right;
  overflow: hidden;
  margin-left: -20px;
  
}
div.a3dview-box.type-v div.in img{
  width: 400px;
  position:absolute;

}

div.a3dview-box.type-v div.right img{
  margin-left: -200px;
}
.a3dview-box.type-v div.right{

  margin-left: 0px;
  transform: perspective(200px) rotateY(10deg);
  transform-origin: center left; 

  overflow: hidden;
}
.a3dview-box.type-v div.in{
  position: relative;
  width: 200px;
  overflow: hidden;
}


div.a3dview-box.type-v div.cover{
  width: 398px;
  top: 0px;
  left: -18px;
}







div.a3dview-box.type-v.r div.left{
  margin-left: 0px;
  transform: perspective(200px) rotateY(10deg);
  transform-origin: center left; 
}

div.a3dview-box.type-v.r div.right img{
  margin-left: -192px;
}
.a3dview-box.type-v.r div.right{
  transform: perspective(200px) rotateY(-10deg);
  transform-origin: center right;
  margin-left: -70px;
}
div.a3dview-box.type-v.r div.cover{
  width: 393px;
  height: 331px;
  top: -13px;
  left: -30px;
}

/**
:root {
  --a3d-width: 360px;
}
.a3dview-box{
  display: flex;
  width: var(--a3d-width);
  flex-direction: row;
  overflow: hidden;
}
.a3dview-box div{
  overflow: hidden;
  display: flex;
}


.a3dview-box.type-v div.left{
  transform: skewY(10deg)
}
.a3dview-box.type-v  div.right{
  transform:  skewY(-15deg);
}


div.a3dview-box.type-v div img{
  width: 250%;
}

div.a3dview-box.type-v  div.left img{
  transform:  translateX(-55px)  translateY(-31px)
}
div.a3dview-box.type-v  div.right img{
  transform:  translateX(-240px)  translateY(-39px)
}


**/
/*** Z ***/ 
/**

.a3dview-box.type-z div.left{
  position: relative;
  transform: perspective(200px) skewY(-10deg);
  perspective-origin: bottom;
  transform-origin: center left;
  background-color: black;
}
.a3dview-box.type-z div.center{
    position: relative; 
    justify-content: center;
    transform: perspective(200px) skewY(10deg);
    transform-origin: center right;
    margin-left: -30px;
}
.a3dview-box.type-z div.right{
  position: relative;
  transform: perspective(200px) skewY(15deg);
  transform-origin: center left;
  justify-content: right;
}




.a3dview-box.type-z div img{
  width: 385%;
}


div.a3dview-box.type-z div.left img{
  margin-left: 40px;
  margin-top: -35px;
  margin-bottom: -35px;
}
div.a3dview-box.type-z  div.center img{
  margin-top: -35px;
  margin-bottom: -35px;
}
div.a3dview-box.type-z  div.right img{
  margin-right: -55px;
  margin-top: -36px;
  margin-bottom: -35px;
}


**/


