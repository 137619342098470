@charset "utf-8";

#page.page #contents .topcomment{
	display: table;
	margin:0 auto 30px;
}

#page #contents #box-link{
	padding:30px 0;
	background-color: #dfeaf0;
}
#page #contents #box-link .top-com{
	margin-bottom:30px;
}
#page #contents #box-link .box{
	width:310px;
	border-right:5px solid #2c90c8;
	padding:20px 0;
	background-color: #fff;
}
#page #contents #box-link .box:nth-child(2){
	border-right:5px solid #6fb458;
}
#page #contents #box-link .box:nth-child(3){
	border-right:5px solid #f08437;
}

	#page #contents #box-link .box .boxin{
		width:270px;
		margin:0 auto;
	}
		#page #contents #box-link .box .boxin h2{
			text-align: center;
			display:table;
			margin:0 auto 25px;
		}
			#page #contents #box-link .box .boxin h2 span{
				font-size:18px;
				font-weight: normal;
				color:#231815;
			}
			#page #contents #box-link .box .boxin h2 div{
				font-size:25px;
				font-weight:bold;
				color:#2c90c8;
			}
			#page #contents #box-link .box:nth-child(2) .boxin h2 div{
				letter-spacing: -1px;
				color: #6fb458;
			}
			#page #contents #box-link .box:nth-child(3) .boxin h2 div{
				color: #f08437;
			}
		#page #contents #box-link .box .boxin p{
			height:6.5em;
		}

	#page #contents #box-link .box .boxin .box-img{
		margin:15px 0 20px;
		width:100%;
	}
		#page #contents #box-link .box .boxin .box-img img{
			width:100%;
		}
	#page #contents #box-link .box .button{
		margin:15px auto 0;
		width:186px;
		height:35px;
		border-radius: 0;
	}
		#page #contents #box-link .box .button a{
			color:#000;
			border:1px solid #717071;
			width:100%;
			height:100%;
			display: flex;
			-webkit-align-items: center;
			align-items: center;
			-webkit-flex-wrap: wrap;
			flex-wrap: wrap;
			-webkit-align-content: center;
			align-content: center;
			-webkit-justify-content: center;
			justify-content: center;
			position: relative;
		}
		#page #contents #box-link .box .button a:hover{
			border:1px solid #2c90c8;
			background-color: #2c90c8
		}
		#page #contents #box-link .box:nth-child(2) .button a:hover{
			border:1px solid #6fb458;
			background-color: #6fb458
		}
		#page #contents #box-link .box:nth-child(3) .button a:hover{
			border:1px solid #f08437;
			background-color: #f08437
		}
			#page #contents #box-link .box .button a span{
				font-size:14px;
				padding-right:14px;
				display:inline-block;
				background-image: url(../images/common/arrow-rb-s.svg);
				background-position: right center;
				background-repeat: no-repeat;
				background-size: 7px 14px;
			}
			#page #contents #box-link .box .button a:hover span{
				color:#fff;
				background-image: url(../images/common/arrow-rw-s.svg);
			}

#page #contents #box-link .link-com{
	text-align:center;
	margin:30px auto 0;
}
	#page #contents #box-link .link-com a{
		color:#2ca6e0;
	}
@media (max-width: 768px) {
	#page #contents #box-link{
		margin-top:-16vw;
		padding:17vw 0 5vw;
	}
	#page #contents #box-link .top-com{
		margin-bottom:5vw;
	}
	#page #contents #box-link{
		display:table;
	}
	#page #contents #box-link .box{
		width:100%;
		float:none;
		border-right:2vw solid #2c90c8;
		padding:2vw 0 5vw 0;
		margin-bottom:4vw;
	}
	#page #contents #box-link .box:nth-child(2){
		border-right:2vw solid #6fb458;
	}
	#page #contents #box-link .box:nth-child(3){
		border-right:2vw solid #f08437;
	}

		#page #contents #box-link .box .boxin{
			width:78vw;
			margin:0 auto;
		}
			#page #contents #box-link .box .boxin h2{
				margin:2vw auto 3vw;
			}
				#page #contents #box-link .box .boxin h2 span{
					font-size:4vw;
					font-weight: normal;
					color:#231815;
				}
				#page #contents #box-link .box .boxin h2 div{
					font-size:6vw;
					padding-top:2vw;
				}
			#page #contents #box-link .box .boxin p{
				height:auto;
			}

		#page #contents #box-link .box .boxin .box-img{
			margin:4vw auto 5vw;
			width:60vw;
		}
		#page #contents #box-link .box .button{
			margin:0 auto 0;
			width:50vw;
			height:10vw;
		}
				#page #contents #box-link .box .button a span{
					font-size:4.2vw;
					padding-right:4vw;
					background-position: right top 0.4vw;
					background-size: 2vw 4vw;
				}

	#page #contents #box-link .link-com{
		text-align:center;
		margin:4vw auto 0;
		line-height:1.2;
	}
}

/*　テンプレートDL　BOX　*/
#page #contents .temp-box{
	display: table;
	min-height:51px;
	background-position: left top;
	background-repeat: no-repeat;
	background-size: 41px 51px;
	padding:4px 0 0 55px;
}
#page.index #contents .temp-box{
	min-height:35px;
	background-size: 28px 35px;
	padding:2px 0 0 38px;
}
	#page #contents .temp-box.temp-ai-box{
		margin:10px 0 0 40px;
		background-image: url(../images/common/icon-ai.png);
	}
	#page.index #contents .temp-box.temp-ai-box{
		margin:0 0 0 0;
	}
	#page #contents .temp-box.temp-xls-box{
		background-image: url(../images/common/icon-xls.png);
	}
	#page #contents .temp-box a{
		color:#2ca6e0;
		font-size:16px;
		line-height:1.4;
		text-decoration: underline;
		position: relative;
		display: table;
		padding-right:30px;
	}
	#page.index #contents .temp-box a{
		font-size:12px;
		padding-right:19px;
	}
		#page #contents .temp-box a:after{
			content: "";
			display: inline-block;
			width: 21px;
			height: 33px;
			background: url(../images/common/icon-dl.png) no-repeat;
			background-size: contain;
			bottom:-9px;
			right:0;
			z-index: 1;
			position: absolute;
		}
		#page.index #contents .temp-box a:after{
			width: 14px;
			height: 12px;
			bottom:3px;
		}
		#page #contents .temp-box a:hover{
			color:#1a44c6;
		}
@media (max-width: 768px) {
	#page #contents .temp-box,
	#page.index #contents .temp-box{
		display: table;
		min-height:10vw;
		background-position: left top;
		background-repeat: no-repeat;
		background-size: 8vw 9.9vw;
		padding:2vw 0 0 12vw;
	}
	#page.index #contents .temp-box{
		margin:3vw 0 0;
	}
		#page #contents .temp-box.temp-ai-box,
		#page.index #contents .temp-box.temp-ai-box{
			margin:4vw 0 7vw 10vw;
			background-image: url(../images/common/icon-ai.png);
		}
		#page.index #contents .temp-box.temp-ai-box{
			margin:0;
		}
		#page #contents .temp-box a,
		#page.index #contents .temp-box a{
			font-size:3.4vw;
			padding-right:5.5vw;
		}
			#page #contents .temp-box a:after,
			#page.index #contents .temp-box a:after{
				width: 4.1vw;
				height: 6.4vw;
				bottom:-2vw;
			}
}

/*　準備BOX　*/
#page #contents .preparations-link-box{
	text-align: center;
	background-color: #fde0a5;
	width:500px;
	height:90px;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: center;
	align-content: center;
	-webkit-justify-content: center;
	justify-content: center;
	margin:0 auto;
}
	#page #contents .preparations-link-box .preparations-link-boxin{
		width:auto;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: center;
		align-content: center;
		-webkit-justify-content: center;
		justify-content: center;
	}
	#page #contents .preparations-link-box p{
		font-size: 24px;
		font-weight: bold;
		line-height:1;
		margin-right:25px;
	}
	#page #contents .preparations-link-box .button{
		width:236px;
		height:50px;
		background-color:#ed772c;
	}
		#page #contents .preparations-link-box .button a{
			width:100%;
			height:100%;
		}
			#page #contents .preparations-link-box .button a span{
				font-size:18px;
				padding-right:20px;
				display:inline-block;
				background-image: url(../images/common/arrow-rw.svg);
				background-position: right center;
				background-repeat: no-repeat;
				background-size: 10px 19px;
			}
		#page #contents .preparations-link-box .button:hover{
			background-color:#d14b00;
		}

@media (max-width: 768px) {
	#page #contents .preparations-link-box{
		width:86vw;
		height:20vw;
	}
		#page #contents .preparations-link-box p{
			font-size: 4vw;
			margin-right:3vw;
		}
		#page #contents .preparations-link-box .button{
			width:44vw;
			height:10vw;
		}
				#page #contents .preparations-link-box .button a span{
					font-size:4vw;
					padding-right:4vw;
					background-size: 1.9vw 3.8vw;
				}
}



/* ***********************************************************
* トップ
* *********************************************************** */
#page.index #contents {
    position: relative;
    padding: 30px 0 60px 0;
}
	#page.index #contents #main #main-box{
		width:100%;
	}
		#page.index #contents #main #main-box img{
			width:100%;
		}
#page.index #contents .topcomment{
	text-align:center;
	margin:45px auto;
}
	#page.index #contents .topcomment .catch-copy{
		width:648px;
		margin:0 auto 20px;
	}
		#page.index #contents .topcomment .catch-copy img{
			width:100%;
		}
	#page.index #contents .topcomment .comment{
		font-size:28px;
	}
	#page.index #contents .topcomment .comment span{
		font-size:40px;
		color:#f08200;
	}



#page.index #contents #box-link{
	margin-bottom:50px;
	padding: 40px 0;
}

#page.index #contents .box-plan{
	margin:0 auto 55px;
}
#page.index #contents .box-plan .box-title{
	background-color: #2c90c8;
	width:100%;
	height:90px;
	padding:0 10px 0 92px;
	background-image: url(../images/method/icon01.png);
	background-position: 10px center;
	background-repeat: no-repeat;
	background-size: auto 70px;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: center;
	align-content: center;
	-webkit-justify-content: space-between;
	justify-content: space-between;
}
#page.index #contents .box-plan.plan02 .box-title{
	background-color: #6fb458;
	background-image: url(../images/method/icon02.png);
}
#page.index #contents .box-plan.plan03 .box-title{
	background-color: #f08437;
	background-image: url(../images/method/icon03.png);
}
	#page.index #contents .box-plan .box-title div{
		font-size:26px;
		font-weight: bold;
		line-height: 1;
		color:#fff;
		float:left;
	}
	#page.index #contents .box-plan .box-title .icon{
		float:right;
		display:table;
	}
		#page.index #contents .box-plan .box-title .icon li{
			width:70px;
			height:70px;
			margin-left:10px;
			float:left;
		}
		#page.index #contents .box-plan .box-title .icon li img{
			width:100%;
		}


	#page.index #contents .box-plan .explanation{
		margin:15px 0 25px;
	}
	#page.index #contents .box-plan .box{
		margin:0 0 25px;
		display:table;
		width:100%;
	}
		#page.index #contents .box-plan .box .l{
			width:640px;
		}
			#page.index #contents .box-plan .box .l .box-howto{
				width:100%;
				background-color: #dfeaf0;
				padding:20px;
			}
				#page.index #contents .box-plan .box .l .box-howto h3{
					width:95px;
					margin-bottom:7px;
					line-height: 1;
				}
					#page.index #contents .box-plan .box .l .box-howto h3 img{
						width:100%;
					}
				#page.index #contents .box-plan .box .l .box-howto p{
					font-size:14px;
				}
				#page.index #contents .box-plan .box .l .box-howto .box-templatelink{
					display:table;
					width:auto;
					margin:10px 0 0;
				}
					#page.index #contents .box-plan .box .l .box-howto .box-templatelink li{
						display:table-cell;
						padding-right:32px;
						text-align: left;
						vertical-align: middle;
					}

		#page.index #contents .box-plan .box .r{
			width:340px;
		}
			#page.index #contents .box-plan .box .r img{
				width:100%;
			}

	#page.index #contents .box-plan .box .box-download{
		width:100%;
		margin:25px 0 0;
	}
		#page.index #contents .box-plan .box .box-download .pattern{
			width:100%;
			display: flex;
			-webkit-align-items: center;
			align-items: center;
			-webkit-flex-wrap: wrap;
			flex-wrap: wrap;
			-webkit-align-content: center;
			align-content: center;
			-webkit-justify-content: space-between;
			justify-content: space-between;
		}
		#page.index #contents .box-plan .box .box-download .pattern.pt-01 .frame{
			border:1px solid #888888;
			width:260px;
			height:120px;
			float:left;
			padding:12px 5px 10px;
			position: relative;
		}
		#page.index #contents .box-plan .box .box-download .pattern.pt-02 .frame{
			border:1px solid #888888;
			width:100%;
			height:100px;
			float:none;
			padding:12px 5px 10px;
		}

		#page.index #contents .box-plan .box .box-download .pattern.pt-01 .frame:nth-child(1):after{
			content: "";
	    display: inline-block;
	    position: absolute;
	    top: 41px;
	    right: -80px;
	    background-image: url(../images/top/preparation03.png);
	    background-size: 38px 38px;
	    background-repeat: repeat-x;
	    background-position: center top;
	    width: 38px;
	    height: 38px;
	    z-index: 1;
		}
		#page.index #contents .box-plan .box .box-download .pattern .frame h4{
			height:35px;
			margin:0 auto 15px;
			text-align:center;
		}
			#page.index #contents .box-plan .box .box-download .pattern .frame h4 img{
				height:100%;
			}
		#page.index #contents .box-plan .box .box-download .pattern .frame p{
			font-size:14px;
			letter-spacing: -1px;
		}

	#page.index #contents .box-plan .box .box-download .link{
		text-align:center;
		margin:20px auto 0;
	}
		#page.index #contents .box-plan .box .box-download .link a{
			font-size:14px;
			color:#2ca6e0;
			text-decoration: underline;
			position: relative;
		}

@media (max-width: 768px) {
	#page.index #contents {
	    position: relative;
	    padding: 6vw 0 12vw 0;
	}
	#page.index #contents .topcomment{
		text-align:center;
		margin:6vw auto;
	}
		#page.index #contents .topcomment .catch-copy{
			width:100%;
			margin:0 auto 4vw;
		}
		#page.index #contents .topcomment .comment{
			font-size:4.4vw;
		}
		#page.index #contents .topcomment .comment span{
			font-size:40px;
			color:#f08200;
		}



	#page.index #contents #box-link{
		margin:0 0 8vw;
		padding: 40px 0;
	}

	#page.index #contents .box-plan{
		margin:0 auto 18vw;
	}
	#page.index #contents .box-plan .box-title{
		padding:4.5vw 0 2vw;
		width:100%;
		height:auto;
		padding-left:14vw;
		background-position: 2vw 2vw;
		background-repeat: no-repeat;
		background-size: 9vw 9vw;
		display: table;
	}
		#page.index #contents .box-plan .box-title div{
			font-size:4.8vw;
			float:none;
			padding-bottom:3vw;
			border-bottom:2px solid #f9f9f9;
		}
		#page.index #contents .box-plan .box-title .icon{
			float:right;
			display:table;
			margin:2vw 2vw 0;
		}
			#page.index #contents .box-plan .box-title .icon li{
				width:12vw;
				height:12vw;
				margin-left:2vw;
				float:left;
			}

		#page.index #contents .box-plan .explanation{
			margin:3vw 0 5vw;
		}
		#page.index #contents .box-plan .box{
			margin:0 0 6vw;
			width:100%;
		}
			#page.index #contents .box-plan .box .l{
				width:100%;
			}
				#page.index #contents .box-plan .box .l .box-howto{
					padding:4vw;
				}
					#page.index #contents .box-plan .box .l .box-howto h3{
						width:20vw;
						margin-bottom:2vw;
					}
					#page.index #contents .box-plan .box .l .box-howto p{
						font-size:3.4vw;
						line-height:1.6;
					}
					#page.index #contents .box-plan .box .l .box-howto .box-templatelink{
						display:table;
						width:auto;
						margin:3vw 0 0;
						text-align: left;
					}
						#page.index #contents .box-plan .box .l .box-howto .box-templatelink li{
							display:block;
							padding:0;
							margin:0 auto;
						}

			#page.index #contents .box-plan .box .r{
				width:60vw;
				margin:0 auto 5vw;
				float:none;
			}


		#page.index #contents .box-plan .box .box-download{
			margin:5vw 0 0;
		}
			#page.index #contents .box-plan .box .box-download .pattern.pt-01 .frame{
				width:40vw;
				height:36vw;
				padding:3vw 2vw 3vw;
			}
			#page.index #contents .box-plan .box .box-download .pattern.pt-02 .frame{
				height:auto;
				padding:3vw 2vw 3vw;
			}
			#page.index #contents .box-plan .box .box-download .pattern.pt-01 .frame:nth-child(1):after{
		    top: 14.5vw;
		    right: -8vw;
		    background-size: 6vw 6vw;
		    width: 6vw;
		    height: 6vw;
			}
			#page.index #contents .box-plan .box .box-download .pattern .frame h4{
				height:5.8vw;
				margin:0 auto 3.5vw;
			}
			#page.index #contents .box-plan .box .box-download .pattern .frame p{
				font-size:3.2vw;
				line-height: 1.5;
			}

		#page.index #contents .box-plan .box .box-download .link{
			text-align:center;
			margin:3vw auto 0;
		}
			#page.index #contents .box-plan .box .box-download .link a{
				font-size:3.4vw;
			}
}


/* ***********************************************************
* 料金表
* *********************************************************** */
#page.price #contents .box-contents{
	display:table;
}
	#page.price #contents .topcomment p{
		text-align: center;
		margin:20px auto 0;
	}
	#page.price #contents .topcomment p a{color:#2ca6e0;}
	#page.price #contents .topcomment p a:hover{color:#1a44c6;}

	#page.price #contents .box-contents .l,
	#page.price #contents .box-contents .r{
		width:48%;
	}
	#page.price #contents .box-contents h2{
		font-size:24px;
	}
		#page.price #contents .box-contents table{
			width:100%;
		}
		#page.price #contents .box-contents table,
		#page.price #contents .box-contents table th,
		#page.price #contents .box-contents table td{
			border:1px solid #ccc;
		}
		#page.price #contents .box-contents table thead th{
			background-color:#6fb458;
			text-align: center;
			vertical-align: middle;
			padding:5px 0;
			color:#fff;
		}
		#page.price #contents .box-contents table tbody th{
			text-align: center;
			vertical-align: middle;
			padding:7px 0;
			width:50%;
			font-weight:normal;
		}
		#page.price #contents .box-contents table tbody td{
			text-align: center;
			vertical-align: middle;
			padding:7px 0;
			width:50%;
		}
		#page.price #contents .box-contents table tr:nth-child(2n) th,
		#page.price #contents .box-contents table tr:nth-child(2n) td{
			background-color: #f9f9f9;
		}
@media (max-width: 768px) {
	#page.price #contents .box-button{
		-webkit-display: flex;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		width:80vw;
		margin:4vw auto 4vw;
	}
		#page.price #contents .box-button li{
			float:left;
			width:37vw;
		}
			#page.price #contents .box-button li a{
				display: block;
				padding:2vw 0;
				font-size:4vw;
				border:1px solid #ccc;
				text-align: center;
			}
			#page.price #contents .box-button li a:hover{
				background-color: #2c90c8;
				color:#fff;
			}

		#page.price #contents .box-contents h2{
			font-size:4.8vw;
			margin-bottom:1vw;
		}
		#page.price #contents .box-contents #z-ori{
			margin-top:10vw;
		}

		#page.price #contents .box-contents .l,
		#page.price #contents .box-contents .r{
			width:100%;
			float:none;
		}
}


/* ***********************************************************
* お支払い・発送について
* *********************************************************** */
#page.payment #contents .box-button{
	-webkit-display: flex;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	width:800px;
	margin:5px auto 60px;
}
	#page.payment #contents .box-button li{
		float:left;
		width:240px;
		height:50px;
	}
		#page.payment #contents .box-button li a{
			border:2px solid #c6c6c6;
			font-weight:bold;
			width:100%;
			height:100%;
			display: flex;
			-webkit-align-items: center;
			align-items: center;
			-webkit-flex-wrap: wrap;
			flex-wrap: wrap;
			-webkit-align-content: center;
			align-content: center;
			-webkit-justify-content: center;
			justify-content: center;
			box-shadow: 4px 4px 0px 0 #c6c6c6;
			border-radius: 0px;
		}
		#page.payment #contents .box-button li a:hover{
			border:2px solid #2c90c8;
			background-color: #2c90c8;
			color:#fff;
		}

#page.payment #contents .stitle-unerline{

}

#page.payment #contents .box-contents{
	margin-bottom:90px;
}
	#page.payment #contents .box-contents .box-contentsin{
		padding:30px 0 30px;
		width:960px;
		margin:0 auto;
	}

	#page.payment #contents .box-contents .box-contentsin strong{
		font-weight:bold;
		font-size:1.2em;
	}

	#page.payment #contents .box-contents#pyment .box-contentsin dl{
		margin:30px 0 0;
		display:table;
		width:100%;
	}
		#page.payment #contents .box-contents#pyment .box-contentsin dl dt{
			background-color:#0075c2;
			padding:15px 10px 15px 15px;
			width:240px;
			height:64px;
			display: flex;
			-webkit-align-items: center;
			align-items: center;
			-webkit-flex-wrap: wrap;
			flex-wrap: wrap;
			-webkit-align-content: center;
			align-content: center;
			-webkit-justify-content: center;
			justify-content: center;
			float:left;
		}
		#page.payment #contents .box-contents#pyment .box-contentsin dl dt div{
			text-align: center;
			line-height: 1.4;
			font-weight:bold;
			font-size:20px;
			color:#fff;
		}
			#page.payment #contents .box-contents#pyment .box-contentsin dl dt span{
				display:block;
				font-size:12px;
				margin-top:;
				font-weight: normal;
			}
		#page.payment #contents .box-contents#pyment .box-contentsin dl dd{
			width:calc(100% - 240px);
			padding:0 0 0 30px;
			display:block;
			float:left;
		}
			#page.payment #contents .box-contents#pyment .box-contentsin dl dd img{
				display:block;
				width:470px;
				margin-top:25px;
			}


#page.payment #contents .box-contents#postage .box-contentsin{
	margin:0 auto 10px;
	border-bottom:2px dotted #cccccc;
	width:960px;
}
#page.payment #contents .box-contents#postage .box-contentsin:last-child{
	border-bottom:none;
}
#page.payment #contents .box-contents#postage .box-contentsin h3{
	font-size:20px;
	font-weight:bold;
	padding:0 0 5px;
}
	#page.payment #contents .box-contents#postage .box-contentsin ul{
		display:table;
	}
		#page.payment #contents .box-contents#postage .box-contentsin ul li{
			float:left;
			margin-right:40px;
		}

	#page.payment #contents .box-contents#postage .box-contentsin h4{
		margin:30px 0 10px;
		font-size:20px;
	}

	#page.payment #contents .box-contents#postage .box-contentsin table.tb01{
		border-top:1px solid #9e9e9f;
		border-bottom:1px solid #9e9e9f;
		margin:0 0 40px;
	}
		#page.payment #contents .box-contents#postage .box-contentsin .tb01 th{
			background-color:#c8c9ca;
			border:1px solid #ededed;
			text-align:center;
			vertical-align: middle;
			font-size:18px;
			font-weight:bold;
			line-height: 1.2;
			width:130px;
			padding:15px;
		}
		#page.payment #contents .box-contents#postage .box-contentsin .tb01 td{
			border-top:1px solid #ededed;
			border-bottom:1px solid #ededed;
			width:calc(100% - 130px);
			padding:20px;
			vertical-align: middle;
		}

	#page.payment #contents .box-contents#postage .box-contentsin .box-img{
		display:table;
		margin-top:10px;
	}
		#page.payment #contents .box-contents#postage .box-contentsin .box-img .l{
			display:table-cell;
			padding-right:40px;
		}
			#page.payment #contents .box-contents#postage .box-contentsin .box-img .l img{
				width:200px;
			}
			#page.payment #contents .box-contents#postage .box-contentsin table.tb02{
				border:2px solid #6b6b6b;
				margin:20px 0 8px;
			}
			#page.payment #contents .box-contents#postage .box-contentsin .tb02 thead th{
				background-color:#dfeaf0;
				border:1px solid #6b6b6b;
				border-bottom:1px solid #6b6b6b;
				text-align:center;
				vertical-align: middle;
				font-weight:bold;
				width:150px;
				padding:5px;
				color:#0075c2;
			}
			#page.payment #contents .box-contents#postage .box-contentsin .tb02 thead th.none{
				width:150px;
				background-color:#0075c2;
				border-bottom:1px solid #fff;
			}
				#page.payment #contents .box-contents#postage .box-contentsin .tb02 tbody th{
					background-color:#0075c2;
					border:1px solid #333;
					border-top:1px solid #fff;
					border-bottom:1px solid #fff;
					text-align:center;
					vertical-align: middle;
					font-weight:bold;
					width:150px;
					padding:5px;
					color:#fff;
				}
				#page.payment #contents .box-contents#postage .box-contentsin .tb02 td{
					border:1px solid #6b6b6b;
					background-color: #fee0b0;
					padding:5px;
					text-align:center;
					vertical-align: middle;
					font-weight:bold;
				}
				#page.payment #contents .box-contents#postage .box-contentsin .tb02 td.none{
					background-image: url(../images/payment/table-line.png);
					background-position: center center;
					background-repeat: no-repeat;
					background-size: 100% 100%;
					background-color: #fff;
				}

	#page.payment #contents .box-contents#cancel .box-contentsin p{
		margin-bottom:1em;
	}

@media (max-width: 768px) {
	#page.payment #contents .box-button{
		-webkit-display: flex;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		width:70vw;
		margin:4vw auto 4vw;
	}
		#page.payment #contents .box-button li{
			float:none;
			width:100%;
			height:auto;
			margin:0 auto 4vw;
		}
			#page.payment #contents .box-button li a{
				display: block;
				padding:2vw 0;
				font-size:4vw;
				border:1px solid #ccc;
				text-align: center;
			}
			#page.payment #contents .box-button li a:hover{
				background-color: #2c90c8;
				color:#fff;
			}

	#page.payment #contents .box-contents{
		margin-bottom:9vw;
	}
		#page.payment #contents .box-contents .box-contentsin{
			padding:5vw 0 9vw;
		}

		#page.payment #contents .box-contents#pyment .box-contentsin dl{
			margin:7vw 0 0;
		}
			#page.payment #contents .box-contents#pyment .box-contentsin dl dt{
				padding:2vw 4vw;
				width:100%;
				float:none;
			}
				#page.payment #contents .box-contents#pyment .box-contentsin dl dt span{
					font-size:0.9em;
				}
			#page.payment #contents .box-contents#pyment .box-contentsin dl dd{
				width:100% ;
				padding:3vw 0 0;
				float:none;
			}
				#page.payment #contents .box-contents#pyment .box-contentsin dl dd img{
					width:100%;
					margin-top:5vw;
				}


	#page.payment #contents .box-contents#postage .box-contentsin{
		margin-bottom:2vw;
	}
	#page.payment #contents .box-contents#postage .box-contentsin h3{
		font-size:4vw;
		padding:0 0 2vw;
	}
			#page.payment #contents .box-contents#postage .box-contentsin ul li{
				float:none;
				font-size:4vw;
				margin-right:0;
			}

		#page.payment #contents .box-contents#postage .box-contentsin h4{
			margin:8vw 0 2vw;
			font-size:4vw;
		}

		#page.payment #contents .box-contents#postage .box-contentsin table.tb01{
			margin:0 0 5vw;
			border:none;
		}
			#page.payment #contents .box-contents#postage .box-contentsin table.tb01 tr {
			  border: none;
			}
			#page.payment #contents .box-contents#postage .box-contentsin .tb01 th{
				font-size:3.8vw;
				font-weight:bold;
				width:100%;
				padding:3vw;
				display:block;
			}
			#page.payment #contents .box-contents#postage .box-contentsin .tb01 td{
				width:100%;
				padding:3vw 0 5vw;
				display:block;
				border:none;
			}

		#page.payment #contents .box-contents#postage .box-contentsin .box-img{
			display:table;
			margin-top:3vw;
		}
			#page.payment #contents .box-contents#postage .box-contentsin .box-img .l{
				display:block;
				padding:0 0 5vw;
			}
				#page.payment #contents .box-contents#postage .box-contentsin .box-img .l img{
					width:50vw;
				}
				#page.payment #contents .box-contents#postage .box-contentsin table.tb02{
					margin:6vw 0 2vw;
					width:100%;
				}
					#page.payment #contents .box-contents#postage .box-contentsin .tb02 th{
						width:28%;
						padding:1.5vw 0;
						text-align:center;
					}
					#page.payment #contents .box-contents#postage .box-contentsin .tb02 th:first-child{
						width:16%;
					}
					#page.payment #contents .box-contents#postage .box-contentsin .tb02 thead th{
						text-align:center;
					}

					#page.payment #contents .box-contents#postage .box-contentsin .tb02 td{
						padding:1.5vw 0;
					}
}




/* ***********************************************************
* 入稿方法
* *********************************************************** */
#page.method #contents .topcomment p{
	text-align: center;
	margin:15px auto 0;
}

#page.method #contents .box-sec01{
	padding:40px 0;
	background-color: #dfeaf0;
}
	#page.method #contents .box-sec01 .plan-info-box dl{
		margin-bottom:20px;
		height:80px;
		width:100%;
		display:table;
	}
		#page.method #contents .box-sec01 .plan-info-box dl dt{
			background-color: #2c90c8;
			font-size:26px;
			font-weight: bold;
			line-height: 1;
			color:#fff;
			width:430px;
			height:100%;
			padding-left:92px;
			background-image: url(../images/method/icon01.png);
			background-position: 10px center;
			background-repeat: no-repeat;
			background-size: 61px 61px;
			display: table-cell;
	    text-align: left;
			vertical-align: middle;
		}
		#page.method #contents .box-sec01 .plan-info-box dl:nth-child(2) dt{
			background-color: #6fb458;
			background-image: url(../images/method/icon02.png);
		}
		#page.method #contents .box-sec01 .plan-info-box dl:nth-child(3) dt{
			background-color: #f08437;
			background-image: url(../images/method/icon03.png);
		}
		#page.method #contents .box-sec01 .plan-info-box dl dd{
			background-color: #fff;
			line-height: 1.6;
			width:calc(100% - 430px);
			height:100%;
			padding:10px 20px;
			display: table-cell;
	    text-align: left;
			vertical-align: middle;
		}

	#page.method #contents .box-sec01 .plan-preparations-box h2{
		font-size:32px;
		font-weight:bold;
		margin:25px 0 10px;
	}
		#page.method #contents .box-sec01 .plan-preparations-box h2 span{
			font-size:32px;
			font-weight:bold;
			color:#0075c2;
		}
	#page.method #contents .box-sec01 .plan-preparations-box h2{
		font-size:32px;
		font-weight:bold;
	}

	#page.method #contents .box-sec01 .plan-preparations-box dl{
		margin-bottom:40px;
		height:90px;
		width:100%;
		display:table;
	}
	#page.method #contents .box-sec01 .plan-preparations-box dl:last-child{
		margin-bottom:0;
	}
		#page.method #contents .box-sec01 .plan-preparations-box dl dt{
			width:390px;
			height:100%;
			display: table-cell;
	    text-align: left;
			vertical-align: middle;
		}
			#page.method #contents .box-sec01 .plan-preparations-box dl dt span{
				display: flex;
				-webkit-align-items: center;
				align-items: center;
				-webkit-flex-wrap: wrap;
				flex-wrap: wrap;
				-webkit-align-content: center;
				align-content: center;
				-webkit-justify-content: center;
				justify-content: center;
				background-color: #2c90c8;
				font-size:26px;
				font-weight: bold;
				line-height: 1;
				color:#fff;
				height:40px;
				width:100%;
			}
			#page.method #contents .box-sec01 .plan-preparations-box dl dt span:nth-child(2){
				background-color: #6fb458;
				margin-top:10px;
			}
			#page.method #contents .box-sec01 .plan-preparations-box dl.preparations-plan03 dt span{
				background-color: #f08437;
				height:100%;
			}
		#page.method #contents .box-sec01 .plan-preparations-box dl dd{
			width:calc(100% - 390px);
			height:100%;
			display: table-cell;
	    text-align: right;
			vertical-align: middle;
		}
		#page.method #contents .box-sec01 .plan-preparations-box dl dd img{
			width:591px;
		}


#page.method #contents .box-sec02{
	padding:50px 0 0;
}
	#page.method #contents .box-sec02 .box{
		width:100%;
		margin-bottom:60px;
	}
	#page.method #contents .box-sec02 .box:last-child{
		margin-bottom:0;
	}
		#page.method #contents .box-sec02 .box h2{
			width:100%;
			margin-bottom:25px;
			background-image: url(../images/method/preparations-title01-line.png);
			background-position: 45px bottom;
			background-repeat: no-repeat;
			background-size: auto 9px;
		}
		#page.method #contents .box-sec02 .box.preparations-csv h2{
			background-image: url(../images/method/preparations-title02-line.png);
		}
			#page.method #contents .box-sec02 .box h2 img{
				width:265px;
			}
		#page.method #contents .box-sec02 .box.preparations-pdf .boxin{
			width:100%;
			margin:40px 0 0;
		}
		#page.method #contents .box-sec02 .box.preparations-pdf .boxin h3{
			font-weight: bold;
			line-height:1.4;
			background-image: url(../images/method/icon-illustrator.png);
			background-position: left bottom;
			background-repeat: no-repeat;
			background-size: 50px 50px;
			padding-left:65px;
		}
			#page.method #contents .box-sec02 .box.preparations-pdf .boxin h3 strong{
				font-size:20px;
				font-weight: bold;
				padding-left:16px;
			}

		#page.method #contents .box-sec02 .box.preparations-pdf .boxin ul{
			margin:30px 0 70px;
			display: table;
			width:100%;
		}
			#page.method #contents .box-sec02 .box.preparations-pdf .boxin li{
				display: table;
				width:440px;
				min-height:102px;
				background-image: url(../images/method/hagaki-z.png);
				background-position: left top;
				background-repeat: no-repeat;
				background-size: 59px 102px;
				padding-left:80px;
			}
			#page.method #contents .box-sec02 .box.preparations-pdf .boxin li.r{
				background-image: url(../images/method/hagaki-w.png);
			}
				#page.method #contents .box-sec02 .box.preparations-pdf .boxin li h4{
					text-align: center;
					background-color: #e60012;
					color:#fff;
					font-size: 18px;
					font-weight: bold;
					line-height:1;
					width:360px;
					height:30px;
					display: flex;
					-webkit-align-items: center;
					align-items: center;
					-webkit-flex-wrap: wrap;
					flex-wrap: wrap;
					-webkit-align-content: center;
					align-content: center;
					-webkit-justify-content: center;
					justify-content: center;
				}




		#page.method #contents .box-sec02 .box.preparations-csv h3{
			margin:20px 0 0;
		}
		#page.method #contents .box-sec02 .box.preparations-csv > p{
			margin:20px 0 30px;
		}
		#page.method #contents .box-sec02 .box.preparations-csv ul{
			margin-left:2.5em;
		}
			#page.method #contents .box-sec02 .box.preparations-csv ul li{
				list-style: disc;
			}
		#page.method #contents .box-sec02 .box.preparations-csv .boxin{
			background-color: #dfeaf0;
			padding:25px 0;
			margin:30px auto 50px;
		}
			#page.method #contents .box-sec02 .box.preparations-csv .boxin .boxin2{
				width:866px;
				margin:0 auto;
			}
				#page.method #contents .box-sec02 .box.preparations-csv .boxin .boxin2 h3 span{
					color:#2ca6e0;
					font-size: 16px;
				}
				#page.method #contents .box-sec02 .box.preparations-csv .boxin .boxin2 .img-box{
					width:100%;
					display:table;
					margin-bottom:30px;
				}
					#page.method #contents .box-sec02 .box.preparations-csv .boxin .boxin2 .img-box .l{
						width:544px;
					}
					#page.method #contents .box-sec02 .box.preparations-csv .boxin .boxin2 .img-box .r{
						width:293px;
					}
						#page.method #contents .box-sec02 .box.preparations-csv .boxin .boxin2 .img-box img{
							width:100%;
						}

	#page.method #contents .box-sec02 .template-link-box{
		width:626px;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: center;
		align-content: center;
		-webkit-justify-content: center;
		justify-content: center;
		margin:0 auto 65px;
	}
		#page.method #contents .box-sec02 .template-link-box p{
			width:360px;
			height:56px;
			margin:0 30px 0 0;
			background-color: #2ca6e0;
			color:#fff;
			font-size:20px;
			font-weight:bold;
			display: flex;
			-webkit-align-items: center;
			align-items: center;
			-webkit-flex-wrap: wrap;
			flex-wrap: wrap;
			-webkit-align-content: center;
			align-content: center;
			-webkit-justify-content: center;
			justify-content: center;
		}
@media (max-width: 768px) {
	#page.method #contents .topcomment p{
		margin:3vw auto 2vw;
	}

	#page.method #contents .box-sec01{
		padding:6vw 0;
	}
		#page.method #contents .box-sec01 .plan-info-box dl{
			margin-bottom:5vw;
			height:auto;
		}
			#page.method #contents .box-sec01 .plan-info-box dl dt{
				padding:4.5vw 0;
				font-size:4.2vw;
				width:100%;
				height:auto;
				padding-left:14vw;
				background-image: url(../images/method/icon01.png);
				background-position: 2vw center;
				background-repeat: no-repeat;
				background-size: 9vw 9vw;
				display: table;
			}
			#page.method #contents .box-sec01 .plan-info-box dl dd{
				background-color: #fff;
				line-height: 1.6;
				width:100%;
				height:auto;
				padding:10px 20px;
				display: table;
		    text-align: left;
				vertical-align: middle;
			}

		#page.method #contents .box-sec01 .plan-preparations-box h2{
			font-size:4.4vw;
			margin:8vw 0 2vw;
		}
			#page.method #contents .box-sec01 .plan-preparations-box h2 span{
				font-size:4.4vw;
			}
		#page.method #contents .box-sec01 .plan-preparations-box h2{
			font-size:4.4vw;
		}

		#page.method #contents .box-sec01 .plan-preparations-box dl{
			margin-bottom:8vw;
			height:auto;
		}
			#page.method #contents .box-sec01 .plan-preparations-box dl dt{
				width:60vw;
				height:100%;
				display: table;
				margin:0 auto 4vw;
			}
				#page.method #contents .box-sec01 .plan-preparations-box dl dt span{
					font-size:4.2vw;
					height:auto;
					padding:2vw 0;
				}
				#page.method #contents .box-sec01 .plan-preparations-box dl dt span:nth-child(2){
					margin-top:2vw;
				}
			#page.method #contents .box-sec01 .plan-preparations-box dl dd{
				width:100%;
				height:auto;
				display: table;
		    text-align: center;
			}
			#page.method #contents .box-sec01 .plan-preparations-box dl dd img{
				width:90vw;
			}


	#page.method #contents .box-sec02{
		padding:9vw 0 0;
	}
		#page.method #contents .box-sec02 .box{
			margin-bottom:9vw;
		}
			#page.method #contents .box-sec02 .box h2{
				margin-bottom:4vw;
				background-position: 10vw bottom;
				background-size: auto 1.8vw;
			}
				#page.method #contents .box-sec02 .box h2 img{
					width:50vw;
				}
			#page.method #contents .box-sec02 .box.preparations-pdf .boxin{
				margin:6vw 0 14vw;
			}
			#page.method #contents .box-sec02 .box.preparations-pdf .boxin h3{
				background-size: 10vw 10vw;
				padding-left:11.5vw;
			}
				#page.method #contents .box-sec02 .box.preparations-pdf .boxin h3 strong{
					font-size:4.5vw;
				}

			#page.method #contents .box-sec02 .box.preparations-pdf .boxin ul{
				margin:6vw auto 4vw;
				width:90vw;
			}
				#page.method #contents .box-sec02 .box.preparations-pdf .boxin li{
					width:100%;
					min-height:auto;
					background-size: 11.5vw 19.9vw;
					padding-left:15vw;
				}
					#page.method #contents .box-sec02 .box.preparations-pdf .boxin li h4{
						font-size: 4vw;
						width:100%;
						height:6vw;
					}



			#page.method #contents .box-sec02 .box.preparations-csv h3{
				margin:4vw 0 0;
			}
			#page.method #contents .box-sec02 .box.preparations-csv > p{
				margin:4vw 0 5vw;
			}
			#page.method #contents .box-sec02 .box.preparations-csv ul{
				margin-left:1.5em;
			}
			#page.method #contents .box-sec02 .box.preparations-csv .boxin{
				padding:4vw 0;
				margin:2vw auto 6vw;
			}
				#page.method #contents .box-sec02 .box.preparations-csv .boxin .boxin2{
					width:80vw;
				}
					#page.method #contents .box-sec02 .box.preparations-csv .boxin .boxin2 h3 span{
						font-size: 3.4vw;
					}
					#page.method #contents .box-sec02 .box.preparations-csv .boxin .boxin2 .img-box{
						margin:4vw 0 8vw;
					}
						#page.method #contents .box-sec02 .box.preparations-csv .boxin .boxin2 .img-box .l{
							width:100%;
						}
						#page.method #contents .box-sec02 .box.preparations-csv .boxin .boxin2 .img-box .r{
							margin-top:4vw;
							width:55vw;
						}


		#page.method #contents .box-sec02 .template-link-box{
			width:100%;
			margin:0 auto 10vw;
		}
			#page.method #contents .box-sec02 .template-link-box p{
				width:35vw;
				height:10vw;
				margin:0 5vw 0 0;
				font-size:3.8vw;
			}

}


/* ***********************************************************
* 入稿の流れ
* *********************************************************** */
#page.flow #contents .topcomment p{
	text-align: center;
	margin:15px auto 40px;
}

#page.flow #contents .title-box{
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: center;
	align-content: center;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	margin-bottom:10px;
}
	#page.flow #contents .title-box h2{
		font-size:30px;
		font-weight: bold;
		color:#0075c1;
		float:left;
	}
	#page.flow #contents .title-box h2.step{
		padding-top:3px;
		padding-left:98px;
		background-image: url(../images/flow/step1.png);
		background-position: left center;
		background-repeat: no-repeat;
		background-size: 80px auto;
	}
	#page.flow #contents .title-box#step2 h2.step{
		background-image: url(../images/flow/step2.png);
	}
	#page.flow #contents .title-box#step3 h2.step{
		background-image: url(../images/flow/step3.png);
	}
	#page.flow #contents .title-box#step4 h2.step{
		background-image: url(../images/flow/step4.png);
	}
	#page.flow #contents .title-box#step5 h2.step{
		background-image: url(../images/flow/step5.png);
	}
	#page.flow #contents .title-box#step6 h2.step{
		background-image: url(../images/flow/step6.png);
	}
	#page.flow #contents .title-box#step7 h2.step{
		background-image: url(../images/flow/step7.png);
	}
	#page.flow #contents .title-box#step8 h2.step{
		background-image: url(../images/flow/step8.png);
	}

	#page.flow #contents .title-box ul{
		display: table;
		float:right;
	}
		#page.flow #contents .title-box ul li{
			width:90px;
			margin-left:10px;
			float:left;
		}
			#page.flow #contents .title-box ul li img{
				width:100%;
			}


/* ****** 入稿データのご用意 ****** */


/* ****** ステップ ****** */
#page.flow #contents .arrow{
	width:100%;
	height:47px;
	display:inline-block;
	background-image: url(../images/flow/arrow_bt.png);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: auto 47px;
	margin:20px auto 15px;
}
	#page.flow #contents .arrow p{
		text-align: center;
		font-size:24px;
		font-weight: bold;
		color:#d13232;
		margin-top:0.3em;
		margin-left:15em;
	}


#page.flow #contents .back-line{
	border-top:4px solid #717071;
	padding:40px 0;
	background-color: #dfeaf0;
}

#page.flow #contents .step-box .box{
	display:table;
}
	#page.flow #contents .step-box .box .img-box{
		width:392px;
		display:table;
	}
		#page.flow #contents .step-box .box .img-box img{
			width:100%;
		}
		#page.flow #contents .step-box .box .comment-box{
			width:575px;
			display:table;
		}
		#page.flow #contents .step-box .box .comment-box.wide{
			width:100%;
		}

			#page.flow #contents .step-box .box .comment-box .comment-boxin{
				margin:0 0 20px;
			}
			#page.flow #contents .step-box .box .comment-box .comment-boxin .atten-box{
				padding:15px 15px 0;
				background-color: #f2f9fd;
				border:1px solid #85959e;
			}
			#page.flow #contents .step-box .box .comment-box .comment-boxin h3{
				color:#2a3b45;
				position: relative;
				margin-bottom:4px;
			}
				#page.flow #contents .step-box .box .comment-box .comment-boxin h3:before{
					content:"■";
					display:inline-block;
				}
			#page.flow #contents .step-box .box .comment-box p{
				margin-bottom:1.5em;
			}
			#page.flow #contents .step-box .box .comment-box .comment-boxin .atten-box p{
				margin-bottom:1em;
			}
			#page.flow #contents .step-box .box .comment-box .bm-none{
				margin-bottom:0;
			}
			#page.flow #contents .step-box .box .comment-box .li-dot  li {
			  position: relative;
			  padding-left: 24px;
				padding-bottom:4px;
			}
			#page.flow #contents .step-box .box .comment-box .li-dot li:before {
			  content: "・";
			  left: 0;
			  position: absolute;
			}
			#page.flow #contents .step-box .box .comment-box .img.card{
				width:470px;
				margin-top:25px;
			}
				#page.flow #contents .step-box .box .comment-box .img img{
					width:100%;
				}

			#page.flow #contents .step-box .box .comment-box .boxin{
				width:100%;
				display:table;
				margin-top:35px;
			}
				#page.flow #contents .step-box .box .comment-box .boxin ul{
					width:582px;
					display:flex;
					flex-wrap:wrap;
					display: flex;
					justify-content: space-between;
					-webkit-justify-content: space-between;
					float:right;
					margin-bottom:5px;
				}
					#page.flow #contents .step-box .box .comment-box .boxin li{
						width:276px;
						display:table;
						float:left;
						margin-bottom:5px;
					}
					#page.flow #contents .step-box .box .comment-box .boxin li .button{
						width: 100%;
				    height: 50px;
				    background-color: #ed772c;
					}
					#page.flow #contents .step-box .box .comment-box .boxin li .button:hover{
						background-color:#d34b00;
					}
					#page.flow #contents .step-box .box .comment-box .boxin li .button a {
					    width: 100%;
					    height: 100%;
					}
					#page.flow #contents .step-box .box .comment-box .boxin li .button a span {
					    font-size: 18px;
					    padding-right: 20px;
					    display: inline-block;
					    background-image: url(../images/common/arrow-rw.svg);
					    background-position: right center;
					    background-repeat: no-repeat;
					    background-size: 10px 19px;
					}

@media (max-width: 768px) {
	#page.flow #contents .topcomment p{
		margin: 3vw auto 5vw;
	}

	#page.flow #contents .title-box{
		margin-bottom:2vw;
	}
		#page.flow #contents .title-box h2{
			font-size:5.2vw;
			float:none;
			width:100%;
			line-height:1.2;
		}
		#page.flow #contents .title-box h2.step{
			padding-top:1vw;
			padding-left:15vw;
			margin-bottom:0.5vw;
			background-image: url(../images/flow/step1.png);
			background-position: left center;
			background-repeat: no-repeat;
			background-size: 13vw auto;
		}
		#page.flow #contents .title-box#step4 h2{
			letter-spacing: -2px;
		}
		#page.flow #contents .title-box#step5 h2{
			letter-spacing: -2px;
		}
		#page.flow #contents .title-box ul{
			float:right;
			margin-top:4vw;
		}
			#page.flow #contents .title-box ul li{
				width:20vw;
				margin-left:0;
				margin-right:2vw;
			}

/* ****** 入稿データのご用意 ****** */


	/* ****** ステップ ****** */
	#page.flow #contents .arrow{
		height:10vw;
		background-size: auto 10vw;
		margin:5vw auto 5vw;
	}
		#page.flow #contents .arrow p{
			font-size:4vw;
			margin-top:2vw;
			margin-left:52vw;
		}


	#page.flow #contents .back-line{
		margin-top:-15vw;
		padding:17vw 0 6vw;
	}
		#page.flow #contents .step-box .box .img-box{
			width:70vw;
			float:none;
			margin:0 auto 6vw;
		}
			#page.flow #contents .step-box .box .comment-box{
				width:100%;
				float:none;
			}
				#page.flow #contents .step-box .box .comment-box .img.card{
					width:80vw;
					margin:6vw auto 0;
				}

				#page.flow #contents .step-box .box .comment-box .boxin{
					margin-top:5vw;
				}
					#page.flow #contents .step-box .box .comment-box .boxin ul{
						width:100%;
						margin-bottom:4vw;
					}
						#page.flow #contents .step-box .box .comment-box .boxin li{
							width:48vw;
							margin-bottom:0;
						}
						#page.flow #contents .step-box .box .comment-box .boxin li:nth-child(2){
							width:38vw;
						}
						#page.flow #contents .step-box .box .comment-box .boxin li .button{
					    height: 10vw;
						}
						#page.flow #contents .step-box .box .comment-box .boxin li .button a span {
					    font-size: 3.8vw;
					    padding-right: 4vw;
					    background-size: 2vw 4vw;
						}
}


/* ***********************************************************
* Ｑ＆Ａ
* *********************************************************** */
#page.qa #contents .topcomment{
	margin-bottom:-13px;
	z-index: 1;
	position: relative;
}
	#page.qa #contents .stitle-line{
		margin-bottom:0;
		display:table;
	}
		#page.qa #contents .stitle-line span{
			margin-bottom:0;
			font-size: 42px;
			font-weight: bold;
			color:#d81400;
			display:inline-block;
		}
		#page.qa #contents .stitle-line span:nth-child(2){
			font-size: 30px;
			color:#231815;
		}
		#page.qa #contents .stitle-line span:nth-child(3){
			color:#0075c1;
		}

#page.qa #contents .box-link{
	padding:20px 0;
	background-color: #dfeaf0;
}
	#page.qa #contents .box-link .box-contents{
		display:table;
	}
		#page.qa #contents .box-link .box-contents ul{
			display:flex;
			flex-wrap:wrap;
			display: flex;
			justify-content: space-between;
			-webkit-justify-content: space-between;
			width:840px;
			margin :0 auto;
		}
			#page.qa #contents .box-link .box-contents ul li{
				box-sizing: border-box;
				padding: 5px 0 5px 0;
				width: 46%;
				list-style: disc;
			}
			#page.qa #contents .box-link .box-contents ul li a{
				font-size: 16px;
				font-weight: bold;
			}
#page.qa #contents .box-qa{
	margin-top:38px;
}
#page.qa #contents .box-qa dl{
	width:100%;
	margin-bottom:30px;
}
	#page.qa #contents .box-qa dl dt{
		width:100%;
		background-color: #f2f2f2;
		border-top:1px solid #000000;
		border-bottom:1px solid #000000;
		position: relative;
		padding:0 20px 0 112px;
		min-height:60px;
		display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
	}
	#page.qa #contents .box-qa dl dt:before{
	  content: "";
	  display: inline-block;
	  width: 73px;
	  height: 82px;
	  background: url(../images/qa/icon-q.png) no-repeat;
	  background-size: contain;
	  top:-7px;
		left:20px;
		z-index: 1;
		position: absolute;
	}
		#page.qa #contents .box-qa dl dt span{
		  height: 100%;
			font-size:20px;
			font-weight: bold;
		}
	#page.qa #contents .box-qa dl dd{
		margin-top:10px;
		width:100%;
		background-color: #dfeaf0;
		position: relative;
		padding:20px 20px 20px 112px;
		min-height:92px;
		display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
		background-image: url(../images/qa/icon-a.png);
		background-position: 20px center;
		background-repeat: no-repeat;
		background-size: 83px 53px;
	}
		#page.qa #contents .box-qa dl dd span{
		  height: 100%;
			font-size:16px;
			line-height:1.6;
		}
			#page.qa #contents .box-qa dl dd span a{
			  color:#2ca6e0;
			}
			#page.qa #contents .box-qa dl dd span a:hover{
			  color:#0e4caf;
			}
@media (max-width: 768px) {
	#page.qa #contents .topcomment{
		margin-bottom:-1.6vw;
	}
			#page.qa #contents .stitle-line span{
				font-size: 8vw;
			}
			#page.qa #contents .stitle-line span:nth-child(2){
				font-size: 5vw;
			}

	#page.qa #contents .box-link{
		padding:4.5vw 0;
	}
			#page.qa #contents .box-link .box-contents ul{
				width:80vw;
				display:table;
			}
				#page.qa #contents .box-link .box-contents ul li{
					padding: 0.7vw 0;
					width: 100%;

				}
				#page.qa #contents .box-link .box-contents ul li a{
					font-size: 3.6vw;
				}
	#page.qa #contents .box-qa{
		margin-top:10vw;
	}
	#page.qa #contents .box-qa dl{
		margin-bottom:9vw;
	}
		#page.qa #contents .box-qa dl dt{
			padding:3vw 2vw 3vw 16.5vw;
		}
		#page.qa #contents .box-qa dl dt:before{
		  width: 11.4vw;
		  height: 12.8vw;
		  top:-1.2vw;
			left:2vw;
		}
			#page.qa #contents .box-qa dl dt span{
				font-size:3.8vw;
				line-height: 1.4
			}
		#page.qa #contents .box-qa dl dd{
			margin-top:2.5vw;
			padding:3vw 2vw 3vw 16.5vw;
			background-position: 2vw 4vw;
			background-size: 12.9vw 8.3vw;
		}
			#page.qa #contents .box-qa dl dd span{
			  height: 100%;
				font-size:3.6vw;
				line-height:1.6;
			}
				#page.qa #contents .box-qa dl dd span a{
				  color:#2ca6e0;
				}
				#page.qa #contents .box-qa dl dd span a:hover{
				  color:#0e4caf;
				}
}

/* ***********************************************************
* 会社概要
* *********************************************************** */
#page.company #contents .box-contents{
	display:table;
}
#page.company #contents .l{
	width:700px;
}
#page.company #contents .r{
	width:250px;
}
	#page.company #contents .r img{
		width:100%;
		display: block;
		margin-bottom:20px;
	}
	#page.company #contents table{
		display: table;
		margin:0 auto;
		width:100%;
		border-top:1px solid #ededed;
	}
		#page.company #contents table tr{
			border-bottom:1px solid #ededed;
		}
			#page.company #contents table th{
				width:170px;
				text-align:left;
				padding:15px 20px;
				background-color: #f4f4f4;
				font-weight: normal
			}
			#page.company #contents table td{
				width:calc(100% - 170px);
				text-align:left;
				vertical-align: middle;
				padding:15px 20px;
			}
				#page.company #contents table td dl{
					display:table;
				}
					#page.company #contents table td dl dt{
						display:table-cell;
						width:7em;
					}
					#page.company #contents table td dl dd{
						display:table-cell;
					}
@media (max-width: 768px) {
	#page.company #contents .l{
		width:100%;
		float:none;
	}
	#page.company #contents .r{
		width:60vw;
		margin:10vw auto 0;
		float:none;
	}
		#page.company #contents .r img{
			margin-bottom:8vw;
		}
				#page.company #contents table th{
					display:block;
					width:100%;
					padding:1.5vw 4vw;
				}
					#page.company #contents table th br{
						display:none;
					}
				#page.company #contents table td{
					display:block;
					width:100%;
					padding:3vw 4vw 4vw;
				}
					#page.company #contents table td dl{
						display:table;
					}
						#page.company #contents table td dl dt{
							display:table-cell;
							width:7em;
						}
						#page.company #contents table td dl dd{
							display:table-cell;
						}
}


/* ***********************************************************
* 特定商取引
* *********************************************************** */
#page.law #contents table{
	display: table;
	margin:0 auto;
	width:100%;
	border-top:1px solid #ededed;
}
	#page.law #contents table tr{
		border-bottom:1px solid #ededed;
	}
		#page.law #contents table th{
			width:170px;
			text-align:left;
			padding:15px 20px;
			background-color: #f4f4f4;
			font-weight: normal
		}
		#page.law #contents table td{
			width:calc(100% - 170px);
			text-align:left;
			vertical-align: middle;
			padding:15px 20px;
		}
@media (max-width: 768px) {
		#page.law #contents table th{
			display:block;
			width:100%;
			padding:1.5vw 4vw;
		}
			#page.law #contents table th br{
				display:none;
			}
		#page.law #contents table td{
			display:block;
			width:100%;
			padding:3vw 4vw 4vw;
		}
}


/* ***********************************************************
* 個人情報
* *********************************************************** */
#page.privacy #contents .box{
	margin-bottom: 30px;
}
	#page.privacy #contents .box h2 {
	  background: #f0f0f0;
	  padding: 12px 15px;
	  margin-bottom: 20px;
	  font-size: 20px;
	  font-weight: bold;
	  line-height: 1.4;
	}
	#page.privacy #contents .box p {
	  margin-bottom: 20px;
	}
	#page.privacy #contents .box p:last-child {
	  margin-bottom: 0;
	}
#page.privacy #contents ul li{
	list-style-type:disc;
	margin-left: 1.6em;
}
#page.privacy #contents ol li{
	margin-bottom:0.8em;
}
#page.privacy #contents ol.ol-pt1{
	margin-left: 1.6em;
}
	#page.privacy #contents ol.ol-pt1 > li{
		list-style-type: decimal;
	}
#page.privacy #contents ol.ol-pt2{
	margin-left: 3.5em;
}
	#page.privacy #contents ol.ol-pt2 > li {
		list-style-type: none;
		counter-increment: cnt;
	}
	#page.privacy #contents ol.ol-pt2 > li::before {
		content: "(" counter(cnt) ")";
		display:inline-block;
		margin-left:-3em;
		width: 3em;
	}
	#page.privacy #contents .box .boxin{
		border: #c8c8c8 solid 1px;
		padding: 15px 20px;
		margin-top: 20px;
		margin-bottom: 20px;
		line-height:1.6;
	}
	#page.privacy #contents .box .boxin dt{
		font-weight:bold;
		font-size:1.2em;
	}
	#page.privacy #contents .box .boxin dd{
		margin-bottom:0.8em;
	}
	#page.privacy #contents .box .boxin dd:last-child{
		margin-bottom:0;
	}
	#page.privacy #contents .box .inner{
		padding: 0 0 0 1.5em;
	}
	#page.privacy #contents .box .right{
		text-align: right;
		display: block;
	}
	#page.privacy #contents .box .sign{
		margin-top:3em;
		text-align: right;
	}



/* ***********************************************************
* サイトマップ
* *********************************************************** */
#page.sitemap #contents .link-box{
	display: flex;

	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: center;
	align-content: center;
	-webkit-justify-content: space-between;
	justify-content: space-between;
}
	#page.sitemap #contents .link-box .top{
		width:100%;
		border-bottom:1px solid #0075c2;
		padding:10px 0;
		margin:5px 0 10px;
	}
	#page.sitemap #contents .link-box .box{
		width:30%;
	}
	#page.sitemap #contents .link-box .box .und-ul{
		margin:0 0 0 2em;
	}
		#page.sitemap #contents .link-box .box li{
			padding:8px 0;
			margin:5px 0;
		}
		#page.sitemap #contents a{
			position: relative;
			display:block;
		}
		#page.sitemap #contents a::before{
			content:"▶";
			display:inline-block;
			color:#ccc;
			padding-right:10px;
		}
		#page.sitemap #contents a:hover{
			color:#0075c2;
		}
		#page.sitemap #contents a:hover::before{
			color:#0075c2;
		}


/* ***********************************************************
* 問い合わせ
* *********************************************************** */
#page.contact .button div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 0;
      line-height: 1;
      font-size: 18px;
      border-radius: 6px;
      font-weight: normal;
  }


#contents .input-button {
	-webkit-display: flex;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}
#contents .input-button input {
  display: none;
}
	#contents .input-button input + label {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		padding: 0;
		background-color: #ed772c;
		color: #fff;
		line-height:1;
		font-size:16px;
		border-radius: 6px;
	}
	#contents .input-button input + label:hover {
		background-color: #0075c1;
		color: #fff;
	}
@media (max-width: 768px) {
	#contents .input-button input + label {
		font-size:4vw;
		border-radius: 1.4vw;
	}
	#contents .input-button input + label:hover {
		color: #fff;
	}
}

/* ******************** エラー ******************** */
#contents .error-box{
	background-color: #ffe6e6;
	padding:5px 20px;
	margin:0 0 0;
	width:auto;
	display:table;
}
#contents .error-box li{
	font-size:14px;
	font-weight:bold;
	color:#c13014;
	position:relative;
  padding: 0 15px 0 25px;
  margin: 7px 0 7px 0px;
}
#contents .error-box li:before{
  counter-increment:inherit;
  content: "";
  display: block;
  position: absolute;
  left: 9px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #c13014;
  top: 42%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (max-width: 768px) {
	#contents .errorbox{
		padding:3vw 4vw;
		margin:0 auto 6vw;
		width:100%;
	}
		#contents .errorbox li{
			font-size:3.6vw;
		  padding: 0 0 0 3vw;
		  margin: 2vw 0;
		}
		#contents .errorbox li:before{
			height: 2vw;
			width: 2vw;
			top: 3vw;
		}
}


/* ******************** 追加配送先 ******************** */
#contents .form-box .box{
	width:100%;
	margin:0 0 50px;
	padding:20px 0 0;
	text-align:center;
}
  #contents .form-box .box .boxin{
  	width:760px;
  	text-align:left;
  	margin:0 auto;
  }
	#contents .form-box .box .form-table{
  	width:100%;
  }
  #contents .form-box .box .form-table caption{
  	font-size: 26px;
  	font-weight: bold;
  	color:#0075c1;
  	padding:10px 0;
  }
  #contents .form-box .form-table tr{
  	border-bottom: 1px solid #999;
  }
  #contents .form-box .form-table th{
  	position: relative;
  	width:330px;
    line-height:1;
  	padding:32px 0 0 15px 10px;
    vertical-align: top;
  }
  #contents .form-box .form-table th .thname{
  	float:left;
  	position: relative;
  	font-size: 18px;
  	text-align: left;
  }
  #contents .form-box .form-table th .required{
  	float:right;
    margin-left: 20px;
    width: 42px;
    height: 16px;
    font-size: 11px !important;
    line-height: 1 !important;
    font-weight: normal;
    color: #fff;
    background-color: #da4b28;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-justify-content: center;
    justify-content: center;
    letter-spacing: 3px;
    padding-left: 3px;
  }
  #contents .form-box .form-table td {
  	padding: 20px 15px 20px;
  	width:calc(100% - 330px);
    text-align: left;
  }

  #contents .form-box .form-table input{
  	padding: 4px;
  	border: none;
  	border-radius: 4px;
  	text-align:left;
  	margin:5px 0;
  	line-height: 1.8;
  	font-size: 1em;
  	border:1px solid #999999;
  	box-sizing: border-box;
  }
  #contents .form-box .form-table input.inputbox02{
  	width:100%;
  }
  #contents .form-box .form-table input.inputbox03{
  	width:4em;
  }
  #contents .form-box .form-table input.inputbox04{
  	width:5em;
  }
  #contents .form-box .form-table select{
  	-webkit-appearance: none;
  	-moz-appearance: none;
  	appearance: none;
  	vertical-align:middle;
  	font-size:14px;
  }
  #contents .form-box .form-table select::-ms-expand {
  	display: none;
  }

  #contents .form-box .form-table textarea{
    width:100%;
    height:180px;
    border: 1px solid #999999;
    border-radius: 4px;
    background-color: #f9f9f9;
  }

  .form-box .err{background-color:#ffe6e6;}
  .form-box .err input,
  .form-box .err textarea,
  .form-box .err select{background-color:#fcc;}
  .form-box .errbox{
    font-weight:bold;
    color:#c30;
  }

  .form-box .buttonbox{
    margin:20px auto;
    text-align: center;
  }


@media (max-width: 768px) {
  #contents .form-box .box{
  	margin:0 0 10vw;
  	padding:6vw 0 0;
  }
    #contents .form-box .box .boxin{
    	width:84vw;
    }
    #contents .form-box .box .form-table{
    	width:100%;
    }
    #contents .form-box .box .form-table caption{
    	font-size: 4.8vw;
    	padding:0 0 2vw 0;
    }
    #contents .form-box .form-table table{
    	width:100%;
    }
    #contents .form-box .form-table th{
    	width:100%;
    	min-height:auto;
    	display: block;
    	padding:4vw 0 4vw 3vw;
    }
    #contents .form-box .form-table th .thname{
    	font-size: 3.8vw;
    }
    #contents .form-box .form-table th .required{
      margin-right: 3vw;
      width: 10vw;
      height: 4vw;
      line-height: 4vw;
      font-size: 2.6vw !important;
    }
    #contents .form-box .form-table td {
    	padding: 2vw 3vw 4vw;
    	width:100%;
      display: block;
    }

    #contents .form-box .form-table input{
    	padding: 1vw 2vw;
    	border: none;
    	border-radius: 1vw;
    	text-align:left;
    	margin:0 0;
    	font-size: 3.4vw;
    	border:1px solid #999999;
    }
    #contents .form-box .form-table input.inputbox03{
    	width:12vw;
    }
    #contents .form-box .form-table input.inputbox04{
    	width:18vw;
    }
    #contents .form-box .form-table select{
    	font-size:3.4vw;
    }
    #contents .form-box .form-table select {
    	width: 60vw;
    	height: 12vw;
    	position: relative;
    	padding: 0 3vw;
    	background-size: 6vw, 100%;
    }

    #contents .form-box .form-table textarea{
      height:42vw;
    }

  .form-box .buttonbox{
    margin:4vw auto;
  }
}





/* ******************** 登録内容のご確認 ******************** */

#page.contact #contents .topcomment p {
    border: none;
    padding: 0;
    display:table;
    width:auto;
    margin:15px auto 0;
}
.contact #contents .form-box .box {
    border: none;
    padding: 0;
}
.contact #contents .form-box .box .atten{
    font-size:14px;
    text-align: left;
    padding-left:1em;
  	text-indent: -1em;
    margin:20px 0 0 10px;
}
.contact #contents .form-box .box .atten:before{
    content:"※";
}
.contact .form-box .buttonbox{
  margin:60px auto 0;
}
@media (max-width: 768px) {
  .contact #contents .form-box .box .atten{
      font-size:3vw;
      text-align: left;
      padding-left:1em;
    	text-indent: -1em;
      margin:2vw 0 0 3vw;
  }
  .contact .form-box .buttonbox{
    margin:9vw auto 0;
  }
}


/* ******************** 登録完了 ******************** */
.contact.completion #contents .topcomment p {
    border: none;
    padding: 30px 0 0;
    width:540px;
}
.contact.completion #contents .buttonbox{
  margin:60px auto 0;
  text-align: center;
}
@media (max-width: 768px) {
  .contact.completion #contents .topcomment p {
      padding: 4vw 0 0;
      width:100%;
  }
  .contact .form-box .buttonbox{
    margin:9vw auto 0;
  }
}



.contact .buttonbox.pt1 .button:nth-child(1){
  margin-right:40px;
}
.contact .button {
	display: inline-block;
}
.contact .button.new {
	width:300px;
}
.contact .button div{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		padding: 0;
		line-height:1;
		font-size:18px;
		border-radius: 6px;
		font-weight: normal;
	}
  .contact .button.or div{
    background-color: #ed772c;
    color: #fff;
  }
	.contact .button.or div:hover {
		background-color: #d34b00;
		color: #fff;
	}
  .contact .button.gr div{
    background-color: #cccccc;
    color: #333;
  }
	.contact .button.gr div:hover {
		background-color: #d34b00;
		color: #fff;
	}
@media (max-width: 768px) {
  .contact .buttonbox.pt1 .button:nth-child(1){
    margin-right:4vw;
  }
	.contact .button  {
		font-size:4vw;
		border-radius: 1.4vw;
	}
}
@media (max-width: 768px) {
  #page.payment #contents .box-contents#postage .box-contentsin,
  #page.payment #contents .box-contents .box-contentsin {
    width:100%;}
  }


  #page.flow #contents .step-box .box .comment-box .boxin li .button a:hover span {
    color:#fff;
  }
  @media (max-width: 768px) {
    #page body, #page dd, #page dt, #page h1, #page h2, #page h3, #page h4, #page h5, #page h6, #page li, #page p, #page a, #page td, #page th, #page address {
      font-size: 3.6vw;
      line-height: 1.8;
    }
    #footer {
      margin-top: 10vw;
    }
    #footer .announcement-box .box {
      width: 40vw;
      margin-bottom: 3vw;
    }
    #footer .announcement-box .box:nth-child(1) {
      width: 46vw;
    }
    #footer .link-box .box {
      width: 100%;
      float: left;
      margin: 4vw 0;
      border-right: none;
      padding: 0;
    }
    #page.payment #contents .box-contents .box-contentsin {
      width: 100%;
    }
    #page.payment #contents .box-contents#postage .box-contentsin {
      width: 100%;
    }
    #page #contents .stitle-unerline {
      font-size: 4.6vw;
      padding: 0 0 2vw 0;
    }
    #page.payment #contents .box-contents#pyment .box-contentsin dl dt {
      padding: 1.5vw 4vw;
      height: auto;
    }
    #page.payment #contents .box-contents#pyment .box-contentsin dl dt div {
      text-align: center;
      line-height: 1.4;
      font-weight: bold;
      font-size: 4vw;
      color: #fff;
    }
    #page.privacy #contents .box {
      margin-bottom: 10vw;
    }
    #page.privacy #contents .box h2 {
      font-size: 4vw;
    }
    #page.sitemap #contents .link-box {
      display: table;
    }
    #page.sitemap #contents .link-box .box {
      width: 100%;
    }

    #contents .delivery-list .box .form-table caption {
      padding: 2vw 0 0;
    }
    .delete .boxin strong {
      font-size: 3.6vw;
    }
    .register #contents .address-table .id-tb td .button {
      margin: 3vw 0;
      width: 48vw;
    }
  }
