@charset "utf-8";
.step #contents{
	padding:40px 0 100px;
}
.step.step3 #contents,
.step.step6 #contents,
.step.step7 #contents,
.step.step8 #contents{
	padding:20px 0 100px;
}
.step.step1 #contents{
	padding:40px 0 80px;
}
.step #contents .section{
	margin-bottom:75px;
	padding:0;
}
.step #contents .topcomment{
	margin:0 auto 0;
}



/* ******************** 固定フッター ******************** */
#footer-step{
	background-color: #f9f9f9;
	border-top:2px solid #0075c1;
	position: fixed;
	bottom:0;
	left:0;
	right:0;
	min-height:100px;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: center;
	align-content: center;
	-webkit-justify-content: center;
	justify-content: center;
  z-index: 10;
}
.step4 #footer-step{
	min-height:66px;
}
#footer-step .s-contentsin{
	display: flex;
	-webkit-justify-content: space-between;
	justify-content: space-between;
}
#footer-step .m{
	margin:0 auto;
	width:520px;
	display:inline-table;
}
#footer-step .button,
.no-file .button{
	display:table;
	border-radius:8px;
	margin:0 auto;
	width:210px;
	height:50px;
	transition: 0.2s linear;
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	transition: all 0.2s linear;
	background-color:#e55200;
	padding: 0;
	margin: 0;
}
.step.step1 #footer-step .button,
.no-file .button{
	width:280px;
}
#footer-step .button.gr{
	background-color: #999;
}
.step4 #footer-step .r .button{
	width:240px;
}
.step4 #footer-step .l{
	width:640px;
}
.step4 #footer-step .l .button{
	float:left;
}
.step4 #footer-step .l p{
	float:right;
	width:400px;
}

#footer-step .m .button{
	width:250px;
}
	#footer-step .button span,
	#footer-step .button > div,
	.no-file .button span{
		color:#fff;
		font-weight:normal;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: center;
		align-content: center;
		-webkit-justify-content: center;
		justify-content: center;
		width:100%;
		height:50px;
		position: relative;
		opacity: 1;
		font-size:18px;
		/** padding:0 10px;  step4 **/
	}
	#footer-step .button  span,
	#footer-step .button div span{
		font-size:16px;
		white-space: nowrap;
	}
	.step3 #footer-step .button  span,
	.step3 #footer-step .button div span{
		letter-spacing: -1px;
	}
	#footer-step .button.forward span::before {
		content: "<";
		padding-right:6px;
	}
	#footer-step .button.next span::after,
	#footer-step .button.rightarrow span::after{
		content: ">";
		padding-left:6px;
	}
	#footer-step .button:hover{
		background-color:#f47a00;
		box-shadow: 0px 2px 0 #7f3300;
		transform: translate3d(0, -2px, 0);
	}

#footer-step .button.off{
	background-color:#a8a8a8;
}
#footer-step .button.off:hover{
	background-color:#a8a8a8;
	box-shadow: 0px 0 0 transparent;
	transform: translate3d(0, 0, 0);
}
#footer-step .button.off div{
	color:#e2e2e2;
	font-weight:normal;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: center;
	align-content: center;
	-webkit-justify-content: center;
	justify-content: center;
	width:100%;
	height:100%;
	position: relative;
	opacity: 1;
	font-size:18px;
}
#footer-step .button.bucol2{
	background-color:#f08300;
}

.step3 #footer-step .l .button{
	width:290px;
}
.step3 #footer-step .m {
  width: 440px;
}
.step3 #footer-step .m .button:first-child{
	width:180px;
}
.step3 #footer-step .m .button:last-child{
	width:240px;
	float:right;
}
.step3 #footer-step .r .button{
	width:200px;
}


	#footer-step .totalprice{
		display:table;
		float:left;
		height:38px;
		margin-right:30px;
		margin-top:10px;
	}
	.step7 #footer-step .totalprice{
		margin-top:0;
	}
		#footer-step .totalprice .total{
			display:table-cell;
			height:100%;
			line-height:1;
			font-size:20px;
			font-weight:bold;
		}
		#footer-step sup{
			font-size: 60%;
			vertical-align: top;
			position: relative;
			top: 5px;
		}
		#footer-step .totalprice .price{
			display:table-cell;
			height:100%;
			line-height:1;
			font-size:38px;
			color:#ff3300;
			font-weight:bold;
		}
		#footer-step .totalprice .price::before {
			content: "￥";
			font-size:28px;
			color:#ff3300;
			font-weight:bold;
		}

	.step1 #footer-step .min,
	.step7 #footer-step .min{
		font-size:11px;
		padding-top: 5px;
	}


/* ******************** タイトル関連 ******************** */
.step h1{
	font-size:42px;
	font-weight:bold;
	color:#0075c1;
	text-align:center;
	margin:0 auto 15px;
}
.titlebox{
	width:100%;
	height:50px;
	background-color: #eaeaea;
	margin-bottom:20px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
	justify-content: space-between;
	flex-wrap: wrap;
	padding:0 20px;
}
	.titlebox .titleboxin{
		height:100%;
		font-size:20px;
		font-weight: bold;
		color:#333333;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
	}
	.titlebox .question{
		width:24px;
	}
		.titlebox .question img{
			width:100%;
		}

/* ******************** 必須 ******************** */
.required{
	margin-left:20px;
	width:42px;
	height:16px;
	font-size:11px !important;
	line-height: 1 !important;
	font-weight: normal;
	color:#fff;
	background-color: #da4b28;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: center;
	align-content: center;
	-webkit-justify-content: center;
	justify-content: center;
	letter-spacing: 3px;
	padding-left:3px;
}



/* ******************** コンテンツ関連 ******************** */
.step #contents .now-step{
	margin:0 auto 40px;
	display:table;
	text-align: center;
}

.step#page #contents .topcomment p {
  margin:0 auto 30px;
	width:auto;
	display:table;
}
.step #contents .topcomment p {
  margin: 0 auto 30px;
  width: auto;
  display: table;
}

.step #contents .icon{
	width:16px;
	display: inline-block;
	margin:0 5px 2px;
	vertical-align: middle;
}
.step #contents .icon img{
	  width:100%;
		vertical-align: middle;
	}
  .step #contents .imgbox{
	margin:0 auto;
	display:table;
}


	.surface-title{
		font-size:30px;
		font-weight:bold;
	}
		.surface-title span{
			font-size:20px;
			font-weight:bold;
			padding-right:8px;
		}
    .step #contents .errorbox{
	background-color: #ffe6e6;
	padding:10px 20px;
	margin:0 auto 30px;
	width:600px;
}
.step #contents .errorbox li{
	font-size:16px;
	font-weight:bold;
	color:#c13014;
	position:relative;
  padding: 0 0 0 20px;
  margin: 7px 0 7px 0px;
}
.step #contents .errorbox li:before{
  counter-increment: list;
  content: "";
  display: block;
  position: absolute;
  left: 0px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #c13014;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.step #contents .error-box{
	background-color: #ffe6e6;
	padding:5px 20px;
	margin:0 auto 0;
	width:auto;
	display:table;
}
.step3 #contents .error-box{
	margin:20px auto 0;
}
.step #contents .shipping-address-table .error-box,
.step #contents .form-table .error-box{
	margin:5px 0 0;
}
.step #contents .error-box li{
	font-size:14px;
	font-weight:bold;
	color:#c13014;
	position:relative;
  padding: 0 15px 0 25px;
  margin: 7px 0 7px 0px;
}
.step #contents .error-box li:before{
  counter-increment:inherit;
  content: "";
  display: block;
  position: absolute;
  left: 9px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #c13014;
  top: 42%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.step #contents .error-box-csv{
	margin:-45px auto 60px;
	display:table;
	width:540px;
}
.step #contents .error-box-csv p{
	color:#c13014;
	margin-bottom:15px;
}



.step #contents table{
  margin:0 auto;
	width:100%;
}
.step #contents table.form-table tr{
	  border-bottom:1px solid #ccc;
	}
	.step #contents table th{
		vertical-align: middle;
		padding: 15px 5px 15px 20px;
		text-align:left;
		font-size:18px;
		min-height:75px;
	}
	.step #contents table th span{
		font-size:18px;
	}
	.step #contents table td{
		text-align:left;
		vertical-align: middle;
		padding:15px 0 15px;
	}
  .step #contents table td .min{
			font-size:12px;
			margin-top:5px;
		}
		.step #contents .list{
		  width:100%;
		  display:table;
		}
    .step #contents .list div{
				padding: 0 40px 0 0;
				display:block;
				float:left;
				font-weight:normal;
				line-height: 1;
			}
			.step #contents .list.vertical div{
				padding: 0  0 10px 0;
				float:none;
			}
			.step #contents .list div span{
			  display: -webkit-flex;
			  display: flex;
			  -webkit-align-items: center;
			  align-items: center;
				line-height: 1;
			}
			.step #contents .list label{
			  line-height:1;
			}
			.step #contents .list.specification div{
			  margin-right:60px;
			}
			.step #contents .list.specification div img{
			  display:block;
				margin:0 auto 10px;
				text-align: center;
			}




/* ******************** フォーム関連 ******************** */
#contents input{
	padding: 4px;
	background-color: #f9f9f9;
	border: none;
	border-radius: 4px;
	text-align:left;
	margin:5px 0;
	line-height: 1.8;
	font-size: 1em;
	border:1px solid #999999;
	box-sizing: border-box;
}
#contents input.inputbox02{
	width:100%;
}
#contents input.inputbox03{
	width:4em;
}
#contents input.inputbox04{
	width:5em;
}
#contents textarea{
  width:100%;
  height:14em;
  padding:10px 10px;
  border:1px solid #ccc;
  box-sizing:border-box;
  line-height: 1.8;
  font-size: 16px;
}
#contents select{
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	vertical-align:middle;
	font-size:14px;
}
#contents select::-ms-expand {
	display: none;
}
#contents .specify-table select{
	position: relative;
	padding: 0 10px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: 1px solid #999;
	background: #eee;

	background: url(../images/common/arrow-select-bt.svg) right 50% no-repeat;
	background-size: 20px, 100%;
}
#contents .list span{display:table;width:100%;}
  #contents .list label{
    font-weight:normal;
    float:left;
  }
#contents .list input[type="radio"],
#contents .list input[type="checkbox"]{
	width:18px;
	height:18px;
	border:1px solid #999999;
	background-color:#fff;
	border-radius:9px;
	margin:-2px 5px 0 0;
}
#contents .list input[type="radio"] + span,
#contents .list input[type="checkbox"] + span{
	margin-top:-3px;
	display:block;
	float:left;
}


/* ******************** 規約同意 ******************** */
#contents .handling{padding:20px 0 0 !important;}
#contents .agreement{
	margin:0 auto;
	padding:0;
	width:90%;
}
#contents .agreement .include{padding:25px;height:160px;overflow-y: scroll;
	margin:15px 0 20px 0;
	border:1px solid #f0f0f0;background-color: #fff;}
#contents .agreement .include h2{margin:25px 0 10px;font-weight:bold;font-size: 16px;}
#contents .agreement .include address{font-style:normal;}
#contents .agreement .include li{
  list-style-position: outside;
  list-style-type: disc;
  margin-left: 1.5em;
  margin-bottom: 0.8em;
  padding-left: 0;
}
#contents .agreement .consent{display:table;margin:0 auto;}

#contents .personal {
	display:table;
	margin:0 auto;
	padding:0 0 20px;
}
#contents .personal label{
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}
	#contents .chk-parts {
		padding-left: 10px;
		position: relative;
		line-height: 18px;
    font-size: 14px;
	}
	#contents .personal input[type="checkbox"] {
		width:18px;
		height:18px;
		margin: 0;
		padding: 0;
	}

#contents .err{
	background-color:#ffe6e6;
}
#contents .err input,
#contents .err textarea,
#contents .err select{background-color:#fcc !important;}
#contents .errbox{
  font-weight:bold;
  color:#c30;
}


/* ******************** 印刷プランボタン ******************** */
.step .input-button {
	width:100%;
	-webkit-display: flex;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}
.step .input-button > div {
  height: 64px;
  width: 184px;
  margin: 0;
  float:left;
}
.step .input-button input {
  display: none;
}
.step .input-button input + label {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		border: 4px solid #cccccc;
		padding: 0;
		background-color: #fff;
		color: #231815;
	}
  .step .input-button input:checked + label {
		background-color: #0075c1;
		border: 4px solid #0075c1;
		color: #fff;
	}
	.step .input-button label span{display:block;;line-height:1;}
	.step .input-button label span:nth-child(1){
			font-size:16px;
		}
  .step .input-button > div:nth-child(2) label span:nth-child(1){
			letter-spacing: -1px;
		}
    .step .input-button label span:nth-child(2){
			font-size:11px;
			padding-top:8px;
		}


/* ******************** ボタン ******************** */
.step .input-button-csvup{
	padding:0 0;
}
.step .input-button-up .buttonbox{
  width:310px;
	height:50px;
	margin:0 auto;
}
#surface .input-button-up .buttonbox{
  width:420px;
	height:60px;
	margin:0 auto;
}
.input-button-up input {
  display: none;
}
	.input-button-up input + label {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		border: none;
		border-radius: 4px;
		padding: 0;
		border:2px solid #ed772c;
		background-color: #fff;
		color: #ed772c;
		font-size:18px;
		line-height:1;
		transition: 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    transition: all 0.2s linear;
		position: relative;
		padding-right:14px;
	}
	.input-button-up input + label:hover  {
		background-color: #ed772c;
		color: #fff;
		border-radius: 10px;
	}
	.input-button-up input + label::after{
		content: "";
	  display: inline-block;
	  background-image: url(../images/step/button-icon-up-or.svg);
	  background-size: 30px 30px;
	  background-repeat: no-repeat;
	  background-position: center center;
		position: absolute;
		top:12px;
		right:10px;
		width:30px;
		height:30px;
	}
	.input-button-up input + label:hover::after{
	  background-image: url(../images/step/button-icon-up-wh.svg);
	}


.input-button-generation{
	margin:40px auto 60px;
}
.input-button-generation .buttonbox{
  width:400px;
	height:50px;
	margin:0 auto;
}
.input-button-generation input {
  display: none;
}
	.input-button-generation input + label {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		border: none;
		border-radius: 4px;
		padding: 0;
		border:2px solid #ed772c;
		background-color: #fff;
		color: #ed772c;
		font-size:18px;
		line-height:1;
		transition: 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    transition: all 0.2s linear;
		position: relative;
		padding-right:14px;
	}
	.input-button-generation input + label:hover  {
		background-color: #ed772c;
		color: #fff;
		border-radius: 10px;
	}
	.input-button-generation input + label::after{
		content: "";
	  display: inline-block;
	  background-image: url(../images/step/button-icon-load-or.svg);
	  background-size: 30px 30px;
	  background-repeat: no-repeat;
	  background-position: center center;
		position: absolute;
		top:7px;
		right:10px;
		width:30px;
		height:30px;
	}
	.input-button-generation input + label:hover::after{
	  background-image: url(../images/step/button-icon-load-wh.svg);
	}


	.step3 .input-button-download{
		margin:40px auto 60px;
	}
	.step3 .input-button-download .buttonbox{
	  width:400px;
		height:50px;
		margin:0 auto;
	}
	.step3 .input-button-download input {
	  display: none;
	}
		.step3 .input-button-download input + label {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			border: none;
			border-radius: 4px;
			padding: 0;
			border:2px solid #ed772c;
			background-color: #fff;
			color: #ed772c;
			font-size:18px;
			line-height:1;
			transition: 0.2s linear;
	    -webkit-transition: all 0.2s linear;
	    -moz-transition: all 0.2s linear;
	    -o-transition: all 0.2s linear;
	    -ms-transition: all 0.2s linear;
	    transition: all 0.2s linear;
			position: relative;
			padding-right:14px;
		}
		.step3 .input-button-download input + label:hover  {
			background-color: #ed772c;
			color: #fff;
			border-radius: 10px;
		}
		.step3 .input-button-download input + label::after{
			content: "";
		  display: inline-block;
		  background-image: url(../images/step/button-icon-down-or.svg);
		  background-size: 30px 30px;
		  background-repeat: no-repeat;
		  background-position: center center;
			position: absolute;
			top:7px;
			right:10px;
			width:30px;
			height:30px;
		}
		.step3 .input-button-download input + label:hover::after{
		  background-image: url(../images/step/button-icon-down-wh.svg);
		}

    .step3 .ori-v div.surface{
      width: 417px;
      height: 317px;
      position: relative;
      overflow: hidden;
      margin-left: 55px;
    }
    .step3 .ori-v div.surface img{
      width: 537px;
      height: 437px;
      position: absolute;
      top: -60px;
      left: -60px;
    }

    .step3 .ori-z div.surface{
      width: 670px;
      height: 342px;
      position: relative;
      overflow: hidden;
      margin-left: 58px;
    }
    .step3 .ori-z div.surface img{
      width: 800px;
      height: 471px;
      position: absolute;
      top: -65px;
      left: -65px;
    }

.step6 .input-button-download{
	margin:40px auto 60px;
}
.step6 .input-button-download .buttonbox{
  width:440px;
	height:70px;
	margin:0 auto;
}
.step6 .input-button-download input {
  display: none;
}
	.step6 .input-button-download input + label {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		border: none;
		border-radius: 8px;
		padding: 0;
		border:2px solid #ed772c;
		background-color: #ed772c;
		color: #fff;
		font-size:20px;
		font-weight:bold;
		line-height:1;
		transition: 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    transition: all 0.2s linear;
		position: relative;
		padding-right:14px;
	}
	.step6 .input-button-download input + label:hover,
	.step6 .input-button-download.comp input + label:hover  {
		background-color: #ed472c;
		border:2px solid #ed472c;
		border-radius: 18px;
	}
	.step6 .input-button-download input + label::after{
		content: "";
	  display: inline-block;
	  background-image: url(../images/step/button-icon-down-wh.svg);
	  background-size: 36px 36px;
	  background-repeat: no-repeat;
	  background-position: center center;
		position: absolute;
		top:15px;
		right:20px;
		width:36px;
		height:36px;
	}
	.step6 .input-button-download input + label:hover::after{
	  background-image: url(../images/step/button-icon-down-wh.svg);
	}

	.step6 .input-button-download.comp input + label {
		border:2px solid #a8a8a8;
		background-color: #a8a8a8;
	}



/* ******************** ステップ 1 ******************** */
.step1 #contents .form-table th{
	width:220px;
}
.step1 #contents .form-table td{
	width: calc(100% - 220px);
}
.step1 #contents .input-button-up {
  padding: 10px 0;
}
.step1 #contents .handling {
    padding: 0;
}
.step1 #contents .csv-numbox {
    padding: 20px 0 0;
		text-align:center;
}
.step1 #contents .csv-numbox .num{
    font-size:40px;
		font-weight: bold;
		padding-top:10px;
}
.step1 #contents .csv-numbox .num span{
    font-size:20px;
		padding-left:4px;
}

/* ******************** ステップ 2 ******************** */
.step2 #contents .csv-check-table{
	border:1px solid #ccc;
	margin-top:15px;
}
	.step2 #contents .csv-check-table thead th{
		text-align:center;
		border:1px solid #ccc;
		padding:10px;
		background-color: #e1f1f9;
	}
	.step2 #contents .csv-check-table thead th .red{
		display:block;
		color:#f00;
		font-size:14px;
		font-weight:normal;
	}
	.step2 #contents .csv-check-table tbody th{
		border:1px solid #ccc;
		width:200px;
		background-color: #f6f6f6;
	}
	.step2 #contents .csv-check-table tbody td{
		border:1px solid #ccc;
		width:calc(100% - 200px);
		padding:15px;
	}
	.step2 #contents .csv-check-table tbody td div{
		font-size:18px;
	}
.step2 #contents .tabe-title{
	width:560px;
	display:table;
	margin:0 auto 10px;
}
.step2 #contents .tabe-title div{
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: center;
	align-content: center;
	-webkit-justify-content: center;
	justify-content: center;
	font-weight:bold;
	font-size:16px;
}
.step2 #contents .tabe-title div:nth-child(1){
	width: 160px;
	margin-left:120px;
	float:left;
}
.step2 #contents .tabe-title div:nth-child(2){
	width: 160px;
	margin-left:45px;
	float:left;
}
.step2 #contents .specify-table{width:560px;}
.step2 #contents .specify-table th{width:120px;}
.step2 #contents .specify-table td{vertical-align: middle;}
.step2 #contents .specify-table td:nth-child(1){width:250px;}
.step2 #contents .specify-table select{
	float:left;
	width: 180px;
	height:40px;
}


.step2 #contents .csv-include{
	padding:0;
	width:100%;
	max-height:500px;
	overflow-y: scroll;
	margin:-30px 0 50px 0;
	border:1px solid #ccc;
	background-color: #fff;
}
.step2 #contents .csv-include table{
	border:1px solid #ccc;
}
.step2 #contents .csv-include table th{
	border:1px solid #ccc;
	padding:5px;
	background-color:#f0f0f0;
	font-weight:normal;
	text-align:center;
	font-size:12px;
}
.step2 #contents .csv-include table td{
	border:1px solid #ccc;
	padding:5px;
	font-size:12px;
}



/* ******************** ステップ 3 ******************** */
.step3 #contents ol {
	margin: 0 auto;
	padding: 0;
	list-style: none;
	display:table;
}
	.step3 #contents ol li {
		padding-left: 25px;
		margin-bottom:4px;
		line-height: 1.6;
		background: left top no-repeat;
		background-size: 18px auto;
	}
	.step3 #contents ol li:nth-child(1) {
		background-image: url(../images/step/num01.svg);
	}
	.step3 #contents ol li:nth-child(2) {
		background-image: url(../images/step/num02.svg);
	}
	.step3 #contents ol li:nth-child(3) {
		background-image: url(../images/step/num03.svg);
	}
	.step3 #contents ol li:nth-child(4) {
		background-image: url(../images/step/num04.svg);
	}
	.step3 #contents ol li a{
		line-height: 1;
		display:inline-block;
		vertical-align: middle;
		margin-top:-6px;
		width:18px;
	}
	.step3 #contents ol li a img{
		width:18px;
		vertical-align: middle;
	}

	.step3 #contents .buttonbox.move{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		width:350px;
		margin:30px auto 40px;
	}
		.step3 #contents .buttonbox.move .button-move{
			width:150px;
			height:40px;
		}
			.step3 #contents .buttonbox.move .button-move a{
				width:100%;
				height:100%;
				display: flex;
				-webkit-align-items: center;
				align-items: center;
				-webkit-flex-wrap: wrap;
				flex-wrap: wrap;
				-webkit-align-content: center;
				align-content: center;
				-webkit-justify-content: center;
				justify-content: center;
				border:1px solid #000;
				font-size:16px;
				color:#000;
				line-height:1;
			}
			.step3 #contents .buttonbox.move .button-move a:hover{
				background-color: #0075c1;
				border:1px solid #0075c1;
				color:#fff;
				text-decoration: none;
			}
.step3 #contents #surface{
	background-color:#f4f4f4;
	padding:20px 0 30px 0;
}
.step3 #contents .ori-v{
	width:538px;
	min-width: 538px;
}
	.step3 #contents .surface-titlebox{
		display: table;
		margin:0 0 10px;
	}
		.step3 #contents .surface-title{
			display: table-cell;
			vertical-align: bottom;
		}
		.step3 #contents .surface-titlebox .ori{
			font-size:20px;
			font-weight:bold;
			padding-right:8px;
			display: table-cell;
			padding-left:20px;
		}

		.step3 #contents .surface-box{
			height:408px;
			width:100%;
		}
		.step3 #contents .surface-box .surface{
			height:100%;
			width:auto;
			text-align: center;
			margin:0 auto;
		}
			.step3 #contents .surface-box img{
				height:408px;
				width:auto;
			}
.step3 #contents #surface .input-button-up{
	margin:20px auto ;
}


/* ******************** ステップ 4 ******************** */
.step.step4 #contents {
  padding: 0 0 100px;
	background-color:#f4f4f4;
	position: relative;
}
	.step4 #contents #control-panel{
		position: fixed;
	}

	.step4 #contents #control-panel .control-panelin{
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: space-between;
		justify-content: space-between;
	  flex-wrap: wrap;
	}

		.step4 #contents #control-panel .buttonbox.pagination{
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: center;
			align-items: center;
			-webkit-justify-content: center;
			justify-content: center;
			width:auto;
		}
		.step4 #contents #control-panel .buttonbox.pagination .button.sample {
			width: 100px;
		}
			.step4 #contents #control-panel .buttonbox.pagination .button.sample br{
				display:none;
			}
		.step4 #contents #control-panel .buttonbox.pagination .button {
		    width: 80px;
		    height: 30px;
		    display: inline-block;
		    margin-right: 10px;
		}
		.step4 #contents #control-panel .buttonbox.pagination .button:last-child {
			margin-right:0;
		}
		.step4 #contents #control-panel .buttonbox.pagination .button input {
		  display: none;
		}
			.step4 #contents #control-panel .buttonbox.pagination .button input + label {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				border: none;
				border-radius: 4px;
				padding: 0;
				background-color: #adadad;
				color: #fff;
				font-size:12px;
				line-height:1;
				transition: 0.2s linear;
		    -webkit-transition: all 0.2s linear;
		    -moz-transition: all 0.2s linear;
		    -o-transition: all 0.2s linear;
		    -ms-transition: all 0.2s linear;
		    transition: all 0.2s linear;
				position: relative;
			}
			.step4 #contents #control-panel .buttonbox.pagination .button input + label:hover  {
				background-color: #ed772c;
				color: #fff;
			}
			.step4 #contents #control-panel .buttonbox.pagination .button input + label span{
				text-align: center;
				font-size:12px;
				line-height:1.2;
			}

		.step4 #contents #control-panel .buttonbox.pagination .page-num{
			display:inline-block;
			line-height: 1;
			margin-right:12px;
			font-size:12px;
			width:120px;
			margin-left:5px;
			margin-right:5px;
		}
			.step4 #contents #control-panel .buttonbox.pagination .page-num input{
				width:60px;
				height:30px;
			}
		.step4 #contents #control-panel .buttonbox.surface{
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: center;
			align-items: center;
			-webkit-justify-content: center;
			justify-content: center;
			width:auto;
			margin:0 10px 0 10px;
		}
			.step4 #contents #control-panel .buttonbox.surface div {
				width: 70px;
				height: 32px;
				display:inline-block;
				margin-right:10px;
				line-height:1;

			}
				.step4 #contents #control-panel .buttonbox.surface input {
				  display: none;
				}
				.step4 #contents #control-panel .buttonbox.surface input + label {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 100%;
					border: none;
					border-radius: 4px;
					padding: 0;
					background-color: #adadad;
					color: #fff;
					font-size:12px;
					line-height:1;
					transition: 0.2s linear;
					-webkit-transition: all 0.2s linear;
					-moz-transition: all 0.2s linear;
					-o-transition: all 0.2s linear;
					-ms-transition: all 0.2s linear;
					transition: all 0.2s linear;
					position: relative;
          cursor: pointer;
				}
				.step4 #contents #control-panel .buttonbox.surface input:checked + label {
					background-color: #ed772c;
					color: #fff;
				}

	.step4 #contents #control-panel .question {
    width: auto !important;
		float:right;
    font-size: 30px;
    text-align: left;
    margin: 0;
	}
		.step4 #contents #control-panel .question a{
			display: inline-block;
      height: 36px;
			font-size:15px;
			color:#000;
			text-decoration: none;
			line-height:1;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center;
		}
		.step4 #contents #control-panel .question a:hover{
			color:#000;
			text-decoration: none;
		}
			.step4 #contents #control-panel .question img{
				width:24px;
				vertical-align: middle;
			}





	.step4 #contents #surface{
		padding:45px 0 0;
	}
	.step4 #contents .topcomment{
		margin:5px auto 15px;
		display:table;
	}
		.step4 #contents .title-box{
			display:table-cell;
			width:auto;
		}
.step4 #contents .title-box h1{
		    font-size: 26px !important;
				line-height:1.4;
				text-align: left !important;
				vertical-align: middle;
			}
		.step4 #contents .topcomment .commentbox{
			display:table-cell;
			padding-left:30px;
			vertical-align: top;
		}
			.step4 #contents .topcomment ul li{
				list-style:disc;
				font-size:13px;
			}
		.step4 #contents .editboxin{
			margin:20px auto;
		}
		.step4 #contents #control-panel{
			height:42px;
			background-color:#fff;
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: center;
			align-items: center;
			-webkit-justify-content: center;
			justify-content: center;
			z-index: 1000000;
		}
		.step4 #contents #control-panel #control-panelin{
			height:30px;
		}

	.step4 #contents .editboxin{
		margin:0 auto;
		text-align:center;
	}
	.step4 #contents .pagename{
		margin:25px auto 0;
		text-align: center;
		width:auto;
		display:table;
	}
	.step4 #contents .pagename span{
		font-weight: bold;
		font-size:18px;
		padding:0 2px;
	}
	.step4 #contents .pagename span:first-child{
		background-color: #0075c1;
		color:#fff;
		padding:4px 15px;
		border-radius: 20px;
	}
	.step4 #contents .pagename span:last-child{
		padding-left:15px;
	}

  .step4 #domContainer{
    height: 563px;
    overflow: hidden;
  }
/* ******************** ステップ 5 ******************** */
#openwindow{
	width:100%;
	height:100vh;
	z-index:100001;
	position: absolute;
	top:0;
	left:0;
}
#openwindow #window-bg {
	width:100%;
	height:100vh;
	background:rgba(0,0,0,0.7);
	position: fixed;
	top:0;
	left:0;
	z-index:100000;
}
#openwindow #contentsbox{
	background-color:#fff;
	margin:-40px auto 0;
	padding:60px 0 0;
	display:table;
	position: relative;
	z-index:100002;
}
	#openwindow #contentsbox #closebox{
		position:absolute;
		right: 20px;
		top: 20px;
		width:auto;
		z-index: 100001;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: center;
		align-content: center;
		-webkit-justify-content: center;
		justify-content: center;
	}
		#openwindow #contentsbox #closebox p{
			padding:0 20px 0 0;
			line-height:1;
		}
		#openwindow #contentsbox #window-toggle {
			visibility:visible;
			width: 36px;
			height: 36px;
			cursor: pointer;
			padding: 0;
			display: flex;
			-webkit-align-items: center;
			align-items: center;
			-webkit-flex-wrap: wrap;
			flex-wrap: wrap;
			-webkit-align-content: center;
			align-content: center;
			-webkit-justify-content: center;
			justify-content: center;
		}
			#openwindow #contentsbox #window-toggle div {
				position: relative;
				z-index: 30002;
				width:100%;
				height:100%;
			}
				#openwindow #contentsbox #window-toggle span {
					display: block;
					position: absolute;
					height: 4px;
					width: 46px;
					background: #0075c1;
					right: 0;
					top:0;
					zoom: 1;
				}

				#openwindow #contentsbox #window-toggle span:nth-child(1) {
					top: 14px;
					-webkit-transform: rotate(-135deg);
					transform: rotate(-135deg);
					zoom: 1;
				}
				#openwindow #contentsbox #window-toggle span:nth-child(2) {
					top: 14px;
					-webkit-transform: rotate(135deg);
					transform: rotate(135deg);
					zoom: 1;
				}

	.step #contents #openwindow h1 {
	    margin: 0 auto 10px;
	}
	#contents #openwindow .topcomment p {
	    margin: 0 auto 0;
	    width: auto;
	    display: table;
	}


	#openwindow #contentsbox .button-area {
		padding: 15px 0 10px;
		background-color:#f4f4f4;
	}
	#openwindow #contentsbox .button-area p {
	    margin: 0 auto 15px;
			text-align:center;
	}
	#openwindow #contentsbox dl{
		display:table;
		width:860px;
		margin:0 auto 12px;
		border:1px solid #eee;
		border-radius: 6px;
		background-color: #fff;
	}
		#openwindow #contentsbox dl dt{
			display:table-cell;
			padding:10px 0;
			text-align:right;
			width:430px;
			vertical-align: middle;
		}
		#openwindow #contentsbox dl dd{
			display:table-cell;
			text-align:left;
			vertical-align: middle;
			padding:10px 0 10px 25px;
		}
			#openwindow #contentsbox dl dt .pagenum{
				width:80px;
				padding: 4px 10px;
			}
			#openwindow #contentsbox dl dt span.numname1{
				padding: 0 5px 0 0;
			}
			#openwindow #contentsbox dl dt span.numname2{
				padding: 0 5px 0 10px;
			}
			#openwindow #contentsbox .input-button-download{
				margin:0 auto 0;
				display: inline-block;
			}
			#openwindow #contentsbox .input-button-download .buttonbox{
				width:340px;
				height:48px;
			}
			#openwindow #contentsbox .input-button-download.specify .buttonbox{
				width:180px;
			}


			#openwindow #contentsbox .input-button-download input + label {
				padding-right:30px;
			}

#openwindow #contentsbox .imagebox{
	width:780px;
	display:table;
	margin:30px auto 0;
}


#openwindow #contentsbox #step-box{
	height:80px;

	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: center;
	align-content: center;
	-webkit-justify-content: center;
	justify-content: center;
	padding:0 20px;
}
#openwindow #contentsbox #step-box #step-boxin{
	display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
#openwindow #contentsbox #step-box .button{
	display:table;
	border-radius:8px;
	margin:0 auto;
	width:210px;
	height:50px;
	transition: 0.2s linear;
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	transition: all 0.2s linear;
	background-color:#e55200;
}
.step3 #openwindow #contentsbox #step-box .button{
	width:auto;
}
#openwindow #contentsbox #step-box .m{
	margin:0 25px;
}
#openwindow #contentsbox #step-box .m .button{
	width:250px;
}
.step3 #openwindow #contentsbox #step-box .m .button{
	width:auto;
}
	#openwindow #contentsbox #step-box .button div{
		color:#fff;
		font-weight:normal;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: center;
		align-content: center;
		-webkit-justify-content: center;
		justify-content: center;
		width:100%;
		height:50px;
		position: relative;
		opacity: 1;
		font-size:18px;
	}
	.step3 #openwindow #contentsbox #step-box .button div{
		padding:0 25px;
	}
	#openwindow #contentsbox #step-box .button div span,
	#openwindow #contentsbox #step-box .button div span{
		font-size:18px;
	}
	#openwindow #contentsbox #step-box .button.forward span::before {
		content: "< ";
	}
	#openwindow #contentsbox #step-box .button.next span::after,
	#openwindow #contentsbox #step-box .button.rightarrow span::after{
		content: " >";
	}
	#openwindow #contentsbox #step-box .button:hover{
		background-color:#f47a00;
		box-shadow: 0px 2px 0 #7f3300;
		transform: translate3d(0, -2px, 0);
	}



/* ******************** ステップ 6 ******************** */
.step6 #contents .topcomment .box{
	width:700px;
	margin:20px auto 0;
	display:table;
}
.step6 #contents .topcomment .box.completion{
	width:auto;
	margin:20px auto 50px;
}
	.step6 #contents .topcomment .box .boxin {
		width:100%;
		display: table;
		background-position: right top;
		background-repeat: no-repeat;
		background-size: auto auto;
	}
  .step6 #contents .topcomment .box .boxin.sv {
    background-image: url(../images/step/step6-v-img.png);
  }
  .step6 #contents .topcomment .box .boxin.sz {
    background-image: url(../images/step/step6-z-img.png);
  }
		.step6 #contents .topcomment .box .boxin .text-box {
			width:460px;
			margin:0 0 15px;
		}
			.step6 #contents .topcomment .box p {
				margin: 0 0 0.7em;
				width: 100%;
				display: block;
			}
.step6 #contents .input-button-generation {
    margin: 20px auto 20px;
}
.step6 #contents .button{
	display:table;
	border-radius:4px;
	margin:0 auto 0;
	width:400px;
	height:50px;
	transition: 0.2s linear;
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	transition: all 0.2s linear;
	background-color:#fff;
	border: 2px solid #ed772c;
}
	.step6 #contents .button a{
		color:#ed772c;
		font-weight:normal;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: center;
		align-content: center;
		-webkit-justify-content: center;
		justify-content: center;
		width:100%;
		height:100%;
		position: relative;
		opacity: 1;
		font-size:18px;
	}
	.step6 #contents .button a span{
		font-size:18px;
	}
	.step6 #contents .button.next span::after {
		content: " >";
	}
	.step6 #contents .button:hover{
		background-color:#ed772c;
	}
	.step6 #contents .button:hover a{
		color:#fff;
	}
.no-file #contents h1{margin:60px auto 20px;}
.no-file #contents .button{width:240px;margin:60px auto 0;}


/* ******************** ステップ 7 ******************** */
.step #contents .address-table caption{
	font-size: 18px;
	font-weight:bold;
	text-align:left;
	padding: 0 0 0 20px;
}
.step #contents .address-table th{
	position: relative;
	width:270px;
	min-height:75px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: space-between;
	justify-content: space-between;
}
.step #contents .address-table th .thname{
	float:left;
	position: relative;
}
.step #contents .address-table th .required{
	float:right;
}
.step #contents .address-table td {
	padding: 7px 15px 7px;
	width:530px;
}
.step #contents .address-table select {
	width: 220px;
	height: 40px;
	position: relative;
	padding: 0 10px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: 1px solid #999;
	background: #eee;

	background: url(../images/common/arrow-select-bt.svg) right 50% no-repeat;
	background-size: 20px, 100%;
}

.step7 #contents .payment .list{
	margin:40px auto;
	display: table;
	width:auto;
  font-size: 14px;
}

.step7 #contents .shipping-address-table{
	margin:0 auto 30px;
}
	.step7 #contents .address-table caption{
		padding-bottom:10px;
	}
	.step7 #contents .address-table th span{
		line-height:1.5;
	}
/* ******************** ステップ 8 ******************** */
.step8 #contents .form-table th{
	width:220px;
}
.step8 #contents .form-table td{
	width: 530px;
}
.step8 #contents .address-table th span{
	line-height:1.5;
}
.step8 #contents .input-button-up {
	padding: 20px 0;
}
.step8 #contents .payment{
	padding: 15px 5px 15px 20px;
	font-size: 16px;
}
.step8 #contents .handling {
	padding: 25px 0 25px;
}
.step8 #contents .handling .comment{
	text-align:center;
	color:#f30;
	font-size:16px;
	font-weight:bold;
	padding: 0;
}



#last-step{
	padding: 30px 0 30px;
	background-color:#f4f4f4;
}
.step8 #last-step .tbox{
	margin:0 auto;
	padding:30px 0 20px;
	display:table;
	background-color:#fff;
	text-align:center;
}
.step8 #last-step .box{
	margin:0 auto;
	width:520px;
	display:inline-table;
}
	.step8 #last-step .totalprice{
		display:table;
		height:auto;
		margin:20px auto;
	}
		.step8 #last-step .total{
			display:table-cell;
			height:100%;
			line-height:1;
			font-size:20px;
			font-weight:bold;
		}
		.step8 #last-step .totalprice .price{
			display:table-cell;
			height:100%;
			line-height:1;
			font-size:38px;
			color:#ff3300;
			font-weight:bold;
		}
		.step8 #last-step .totalprice .price::before {
			content: "￥";
			font-size:28px;
			color:#ff3300;
			font-weight:bold;
		}
.step8 #last-step .button{
	display:table;
	border-radius:8px;
	margin:0 auto;
	width:200px;
	height:50px;
	transition: 0.2s linear;
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	transition: all 0.2s linear;
	background-color:#e55200;
}
.step8 #last-step .r .button{
	width:290px;
}
	.step8 #last-step .button div{
		color:#fff;
		font-weight:normal;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: center;
		align-content: center;
		-webkit-justify-content: center;
		justify-content: center;
		width:100%;
		height:100%;
		position: relative;
		opacity: 1;
		font-size:18px;
	}
	.step8 #last-step .button div span,
	.step8 #last-step .button div span{
		font-size:18px;
	}
	.step8 #last-step .button.forward span::before {
		content: "< ";
	}
	.step8 #last-step .button.next span::after,
	.step8 #last-step .button.rightarrow span::after {
		content: " >";
	}
	.step8 #last-step .button:hover{
		background-color:#f47a00;
		box-shadow: 0px 2px 0 #7f3300;
		transform: translate3d(0, -2px, 0);
	}
	.step8 #last-step .button a span span{
		font-size:14px;
	}
	.step8 #last-step .button.next span span::after,
	.step8 #last-step .button.rightarrow span span::after {
		content: "";
	}
/* ******************** ステップ 9 ******************** */
.step9 #contents .topcomment{
	margin:50px auto 0;
	width:600px;
}
.step9 #contents .box{
	text-align: center;
	display:table;
	margin:25px auto 20px;
}
	.step9 #contents h2{
		text-align: center;
		font-size:18px;
		font-weight:bold;
		background-color: #2c90c8;
		color: #fff;
		width:400px;
		padding:4px 0;
		margin:0 auto 15px;
	}
	.step9 #contents address{
		margin:0 auto 25px;
	}
	.step9 #contents .tel{
		font-style: normal;
	}
		.step9 #contents .tel a{
			font-size:32px;
			font-weight:bold;
		}
		.step9 #contents .tel a span{
			font-size:18px;
		}
	.step9 #contents .time{
	font-style: normal;
	}
	.step.button a{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		padding: 0;
		line-height:1;
		font-size:18px;
		border-radius: 6px;
		font-weight: normal;
	}
  .step.button.or a{
    background-color: #ed772c;
    color: #fff;
  }
	.step.button.or a:hover {
		background-color: #d34b00;
		color: #fff;
	}



	#openwindow #contentsbox.contentsin {
	  padding: 60px 20px;
	}
	.step3 #openwindow #contentsbox.contentsin {
	  padding: 30px 0 0;
    background-color: #f4f4f4;
	}
	.step7 #openwindow #contentsbox {
	    background-color: #fff;
	    margin: -40px auto 0;
	    padding: 60px 0 0;
	    display: table;
	    position: relative;
	    z-index: 100002;
	}

	.step7 #openwindow #contentsbox .topcomment{
		width:640px;
    margin: 0 auto;
	}
	.step7 #openwindow #contentsbox .topcomment h2{
		font-size: 32px;
    font-weight: bold;
    color: #0075c1;
    text-align: center;
    margin: 0 auto 15px;
	}

	.step7 #openwindow #contentsbox .topcomment p{
    margin: 0 0 25px;
	}
	.step7 #openwindow #contentsbox .topcomment p.ct{
		text-align: center;
		margin: 0 auto 25px;
	}
	.step7 #openwindow #contentsbox.delivery-list{
		padding-bottom:0;
	}
	.step7 #openwindow #contentsbox.delivery-list .box{
    margin: 0 auto 40px;
		padding:10px 20px 20px;
		border:1px solid #ccc;
		width:760px;
	}
	.step7 #openwindow #contentsbox.delivery-list .box .errorbox {
    padding: 5px 20px;
    margin: 0 auto 10px;
	}

	.step7 #openwindow #contentsbox.delivery-list .box caption{
		font-size: 20px;
    font-weight: bold;
    text-align: center;
		padding:15px 0 10px;
	}
	.step7 #openwindow #contentsbox.delivery-list .box th {
    width: 240px;
    padding: 0 15px 0 10px;
		min-height: 60px;
	}
	.step7 #openwindow #contentsbox.delivery-list .box th span {
    font-size: 16px;
		line-height:1.4;
	}
	.step7 #openwindow #contentsbox.delivery-list .box td {
    width:478px;
	}

	.setp .delivery-list .buttonbox {
	  margin: 20px auto 0;
	}
.delivery-list .buttonbox .comp{
	  width:100%;
		display:table;
		text-align: center;
		padding:5px 0 25px;
		font-size:16px;
		font-weight:bold;
	}
	.step .delivery-list .buttonbox.pt1 {
	  width:460px;
		-webkit-display: flex;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;

	}

	.delivery-list .button{
		border-radius: 6px;
	}

	.delivery-list .button a,
	.delivery-list .button div {
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  justify-content: center;
	  width: 100%;
	  height: 100%;
	  padding: 0;
	  line-height: 1;
	  font-size: 18px;
	  border-radius: 6px;
	  font-weight: 400
	}

	.delivery-list .button.or a,
	.delivery-list .button.or div {
	  background-color: #ed772c;
	  color: #fff
	}

	.delivery-list .button.or a:hover,
	.delivery-list .button.or div:hover {
	  background-color: #d34b00;
	  color: #fff
	}

	.delivery-list .button.rd a,
	.delivery-list .button.rd div{
		background-color:#c82600;
		color:#fff;
	}
  .delivery-list .button.rd a:hover,
	.delivery-list .button.rd div:hover{
    background-color:#8e1700;
    color:#fff;
  }


.step #contents .topcomment p {
    margin: 0 auto 30px;
    width: auto;
    display: table;
}


.pdfme-dummy{
  color: #ff0000 !important;
  opacity: 0;
}
